<template>
  <span class="process-label" v-html="value"></span>
</template>

<script>
export default {
  props: {
    value: {
      type: String,
      required: true,
    },
  },
};
</script>

<style lang="scss" scoped>
.process-label {
  background-color: var(--ion-color-primary-contrast);
  color: var(--ion-color-primary);
}
@media screen and (min-width: 576px) {
  .process-label {
    font-size: 8px;
    padding-left: 2px;
    padding-right: 2px;
  }
}

@media screen and (min-width: 768px) {
  .process-label {
    font-size: 8px;
    padding-left: 3px;
    padding-right: 3px;
  }
}

@media screen and (min-width: 992px) {
  .process-label {
    font-size: 11px;
    padding-left: 4px;
    padding-right: 4px;
  }
}

@media screen and (min-width: 1200px) {
  .process-label {
    font-size: 14px;
    padding-left: 5px;
    padding-right: 5px;
  }
}
</style>
