<template>
  <AlertCard
    v-if="this.product"
    color="primary"
    :title="this.product.attributes[`label_${$i18n.locale}`]"
    cardStyle="quote"
  >
    {{ this.product.attributes[`description_${$i18n.locale}`] }}
  </AlertCard>
</template>

<script>
import AlertCard from "../AlertCard.vue";
export default {
  components: { AlertCard },
  computed: {
    product() {
      return this.$store.getters["products/getById"](this.$store.state.settings.productId);
    },
  },
  mounted() {
    this.$store.dispatch("products/fetchAll");
  },
};
</script>

<style lang="scss" scoped></style>
