<template>
  <div class="calculation-label-container">
    <calculation-label :value="$t('mainCalculations.desalinationElectricityLabel.value')"></calculation-label>
  </div>
</template>

<script>
import CalculationLabel from "./CalculationLabel.vue";
export default { components: { CalculationLabel } };
</script>

<style lang="scss" scoped>
.calculation-label-container {
  position: absolute;
}

@media screen and (min-width: 576px) {
  .calculation-label-container {
    top: 138px;
    left: 201px;
  }
}
@media screen and (min-width: 768px) {
  .calculation-label-container {
    top: 194px;
    left: 273px;
  }
}
@media screen and (min-width: 992px) {
  .calculation-label-container {
    top: 267px;
    left: 368px;
  }
}
@media screen and (min-width: 1200px) {
  .calculation-label-container {
    top: 319px;
    left: 440px;
  }
}
</style>
