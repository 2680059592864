<template>
  <calculation-value :prefix="prefix" :value="value" :suffix="suffix" id="co2-value"></calculation-value>
</template>

<script>
import CalculationValue from "./CalculationValue.vue";
export default {
  components: {
    CalculationValue,
  },
  props: {
    prefix: {
      type: String,
      required: false,
    },
    value: {
      type: String,
      required: true,
    },
    suffix: {
      type: String,
      required: false,
    },
  },
};
</script>

<style lang="scss" scoped>
#co2-value {
  top: 547px;
  left: 924px;
}

@media screen and (min-width: 576px) {
  #co2-value {
    top: 414px;
    left: 384px;
  }
}
@media screen and (min-width: 768px) {
  #co2-value {
    top: 563px;
    left: 522px;
  }
}
@media screen and (min-width: 992px) {
  #co2-value {
    top: 762px;
    left: 708px;
  }
}
@media screen and (min-width: 1200px) {
  #co2-value {
    top: 910px;
    left: 846px;
  }
}
</style>
