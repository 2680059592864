<template>
  <ion-list>
    <ion-item v-for="electricityType in electricityTypes" :key="electricityType.id">
      <ion-checkbox
        slot="start"
        @update:modelValue="check(electricityType.id, $event)"
        :modelValue="electricityTypesIncludeId(electricityType.id)"
      >
      </ion-checkbox>
      <ion-label>
        {{ electricityType.attributes[`label_${$i18n.locale}`] }}
      </ion-label>
    </ion-item>
  </ion-list>
</template>

<script>
import { mapState, mapGetters, mapMutations } from "vuex";
import { mapFields } from "vuex-map-fields";
import { IonItem, IonCheckbox, IonLabel, IonList } from "@ionic/vue";
export default {
  components: {
    IonItem,
    IonCheckbox,
    IonLabel,
    IonList,
  },
  methods: {
    ...mapMutations("settings", ["selectElectricityTypeId"]),
    check: function (id, event) {
      this.$store.commit("settings/selectElectricityTypeId", { id: id, event: event });
    },
    isChecked: (id) => {
      return this.electricityTypesIncludeId(id);
    },
  },
  computed: {
    ...mapState("electricityTypes", {
      electricityTypes: (state) => state.electricityTypes,
    }),
    ...mapFields("settings", ["electricityTypesIds"]),
    ...mapGetters("settings", ["electricityTypesIncludeId"]),
  },
  mounted: async function () {
    await this.$store.dispatch("electricityTypes/fetchAll");
    let electricityTypes = await this.$store.getters["electricityTypes/getAll"]();
    let electricityTypeIds = electricityTypes
      .filter((et) => et.attributes.identifier != "nonRenewable")
      .map((et) => et.id);
    this.$store.commit("settings/setElectricityTypeIds", electricityTypeIds);
  },
};
</script>

<style lang="scss" scoped></style>
