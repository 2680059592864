<template>
  <ion-list>
    <ion-item v-for="co2Source in co2Sources" :key="co2Source.id" :value="co2Source.id">
      <ion-checkbox
        slot="start"
        @update:modelValue="check(co2Source.id, $event)"
        :modelValue="co2SourceIdsIncludeId(co2Source.id)"
      >
      </ion-checkbox>
      <ion-label>{{ co2Source.attributes[`label_${$i18n.locale}`] }}</ion-label>
    </ion-item>
  </ion-list>
</template>

<script>
import { mapState, mapGetters } from "vuex";
import { IonList, IonCheckbox, IonItem, IonLabel } from "@ionic/vue";
export default {
  components: {
    IonList,
    IonCheckbox,
    IonItem,
    IonLabel,
  },
  methods: {
    check: function (id, event) {
      this.$store.commit("settings/selectCo2SourceId", { id: id, event: event });
    },
    isChecked: (id) => {
      return this.co2SourceIdsIncludeId(id);
    },
  },
  computed: {
    ...mapState(["getByCo2SourceCategoryId"]),
    ...mapGetters("settings", ["co2SourceIdsIncludeId"]),
    co2Sources() {
      return this.$store.getters["co2Sources/getByCo2SourceCategoryId"](this.$store.state.settings.co2SourceCategoryId);
    },
  },
  mounted() {
    this.$store.dispatch("co2Sources/fetchAll");
  },
};
</script>

<style lang="scss" scoped></style>
