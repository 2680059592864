import axios from "axios";

export default {
  namespaced: true,
  state: {
    co2SourceCategories: [],
  },
  getters: {
    getAll: (state) => () => {
      return state.co2SourceCategories;
    },
    getById: (state) => (id) => {
      return state.co2SourceCategories.find((co2SourceCategory) => co2SourceCategory.id === id);
    },
    getByIdentifier: (state) => (identifier) => {
      return state.co2SourceCategories.find((co2SourceCategory) => co2SourceCategory.attributes.identifier === identifier);
    },
    getDefaultCo2SourceCategory: (state) => () => {
      return state.co2SourceCategories.find((co2SourceCategory) => co2SourceCategory.attributes.label_de === "DAC");
    },
  },
  actions: {
    async fetchAll({ commit }) {
      try {
        const data = await axios.get(
          `${process.env.VUE_APP_BACKEND_BASE_URL}/co-2-source-categories?populate[]=co2_sources`,
          {
            headers: {
              "Content-Type": "application/json",
              "Accept": "application/json",
              Authorization: `Bearer ${process.env.VUE_APP_BACKEND_API_TOKEN}`,
            },
          }
        );
        commit("SET_CO2_SOURCE_CATEGORIES", data.data.data);
      } catch (error) {
        console.log(error);
      }
    },
  },
  mutations: {
    SET_CO2_SOURCE_CATEGORIES(state, co2SourceCategories) {
      state.co2SourceCategories = co2SourceCategories;
    },
  },
};
