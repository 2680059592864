<template>
  <div class="calculation-label-container">
    <calculation-label :value="$t('mainCalculations.hydrogenLabel.value')"></calculation-label>
  </div>
</template>

<script>
import CalculationLabel from "./CalculationLabel.vue";
export default { components: { CalculationLabel } };
</script>

<style lang="scss" scoped>
.calculation-label-container {
  position: absolute;
}

@media screen and (min-width: 576px) {
  .calculation-label-container {
    top: 234px;
    left: 387px;
  }
}
@media screen and (min-width: 768px) {
  .calculation-label-container {
    top: 324px;
    left: 526px;
  }
}
@media screen and (min-width: 992px) {
  .calculation-label-container {
    top: 442px;
    left: 711px;
  }
}
@media screen and (min-width: 1200px) {
  .calculation-label-container {
    top: 529px;
    left: 849px;
  }
}
</style>
