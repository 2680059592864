import axios from "axios";

export default {
  namespaced: true,
  state: {
    electricityTypes: [],
  },
  getters: {
    getAll: (state) => () => {
      return state.electricityTypes;
    },
    getById: (state) => (id) => {
      return state.electricityTypes.find((electricityType) => electricityType.id === id);
    },
    getByIdentifier: (state) => (identifier) => {
      return state.electricityTypes.find((electricityType) => electricityType.attributes.identifier === identifier);
    },
  },
  actions: {
    async fetchAll({ commit }) {
      try {
        const data = await axios.get(`${process.env.VUE_APP_BACKEND_BASE_URL}/electricity-types`, {
          headers: {
            "Content-Type": "application/json",
            "Accept": "application/json",
            Authorization: `Bearer ${process.env.VUE_APP_BACKEND_API_TOKEN}`,
          },
        });
        commit("SET_ELECTRICITY_TYPES", data.data.data);
      } catch (error) {
        console.log(error);
      }
    },
  },
  mutations: {
    SET_ELECTRICITY_TYPES(state, electricityTypes) {
      state.electricityTypes = electricityTypes;
    },
  },
};
