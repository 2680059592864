import axios from "axios";

export default {
  namespaced: true,
  state: {
    products: [],
  },
  getters: {
    getAll: (state) => () => {
      return state.products;
    },
    getById: (state) => (id) => {
      return state.products.find((product) => product.id === id);
    },
    getByIdentifier: (state) => (identifier) => {
      return state.products.find((product) => product.attributes.identifier === identifier);
    },
    getDefaultProduct: (state) => () => {
      return state.products.find((product) => product.attributes.identifier === "hydrogen");
    },
  },
  actions: {
    async fetchAll({ commit }) {
      try {
        const data = await axios.get(`${process.env.VUE_APP_BACKEND_BASE_URL}/products?populate[]=product_category`, {
          headers: {
            "Content-Type": "application/json",
            "Accept": "application/json",
            Authorization: `Bearer ${process.env.VUE_APP_BACKEND_API_TOKEN}`,
          },
        });
        commit("SET_PRODUCTS", data.data.data);
      } catch (error) {
        console.log(error);
      }
    },
  },
  mutations: {
    SET_PRODUCTS(state, products) {
      state.products = products;
    },
  },
};
