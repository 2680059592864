<template>
  <ion-select interface="popover" placeholder="Bitte wählen Sie einen Quellentyp aus" v-model="co2SourceCategoryId">
    <ion-select-option
      v-for="co2SourceCategory in co2SourceCategories"
      :key="co2SourceCategory.id"
      :value="co2SourceCategory.id"
    >
      {{ co2SourceCategory.attributes[`label_${$i18n.locale}`] }}
    </ion-select-option>
  </ion-select>
</template>

<script>
import { mapState, mapGetters } from "vuex";
import { mapFields } from "vuex-map-fields";
import { IonSelect, IonSelectOption } from "@ionic/vue";
export default {
  components: {
    IonSelect,
    IonSelectOption,
  },
  computed: {
    ...mapState("co2SourceCategories", {
      co2SourceCategories: (state) => state.co2SourceCategories,
    }),
    ...mapGetters("co2SourceCategories", ["getDefaultCo2SourceCategory"]),
    ...mapFields("settings", ["co2SourceCategoryId"]),
  },
  mounted: async function () {
    await this.$store.dispatch("co2SourceCategories/fetchAll");
    this.$store.commit("settings/setCo2SourceCategoryId", {
      id: this.getDefaultCo2SourceCategory().id,
    });
  },
};
</script>

<style lang="scss" scoped></style>
