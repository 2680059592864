<template>
  <calculations-card :title="$t('mainCalculationsWater.title')" icon="waterOutline">
    <div>
      <div>{{ $t("mainCalculationsWater.explanations.neededWater", { waterMt: waterMt }) }}</div>
      <div></div>
      <div v-if="waterSource == 'freshwater'">
        {{
          $t("mainCalculationsWater.explanations.neededWaterFraction", {
            waterFractionOfFreshWaterPercent: waterFractionOfFreshWaterPercent,
          })
        }}
      </div>
    </div>
  </calculations-card>
</template>

<script>
import CalculationsCard from "../components/MainCalculations/CalculationsCard.vue";
export default {
  components: {
    CalculationsCard,
  },
  props: ["waterMt", "waterFractionOfFreshWaterPercent", "waterSource"],
};
</script>

<style lang="scss" scoped>
ion-card {
  top: 20px;
  height: 70px;
  * {
    font-size: 75%;
  }
}

@media screen and (min-width: 576px) {
  ion-card {
    top: 20px;
    height: 70px;
  }
}
@media screen and (min-width: 768px) {
  ion-card {
    top: 10px;
    height: 140px;
    * {
      font-size: 80%;
    }
  }
}
@media screen and (min-width: 992px) {
  ion-card {
    top: 30px;
    height: 160px;
    * {
      font-size: 90%;
    }
  }
}
@media screen and (min-width: 1200px) {
  ion-card {
    top: 50px;
    height: 190px;
    * {
      font-size: 100%;
    }
  }
}
</style>
