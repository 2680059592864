<template>
  <div class="calculation-label-container">
    <calculation-label :value="$t('mainCalculations.electricityOwnUseLabel.value')"></calculation-label>
  </div>
</template>

<script>
import CalculationLabel from "./CalculationLabel.vue";
export default { components: { CalculationLabel } };
</script>

<style lang="scss" scoped>
.calculation-label-container {
  position: absolute;
}

@media screen and (min-width: 576px) {
  .calculation-label-container {
    top: 158px;
    left: 199px;
  }
}
@media screen and (min-width: 768px) {
  .calculation-label-container {
    top: 221px;
    left: 269px;
  }
}
@media screen and (min-width: 992px) {
  .calculation-label-container {
    top: 302px;
    left: 364px;
  }
}
@media screen and (min-width: 1200px) {
  .calculation-label-container {
    top: 363px;
    left: 435px;
  }
}
</style>
