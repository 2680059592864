<template>
  <ion-card>
    <ion-accordion-group value="open">
      <ion-accordion value="open">
        <ion-item slot="header">
          <ion-card-header>
            <ion-card-title>
              <ion-icon :icon="calculatorOutline"></ion-icon>
              <ion-label>{{ $t("mainCalculations.title") }}</ion-label>
            </ion-card-title>
          </ion-card-header>
        </ion-item>
        <template v-if="this.getError()">
          <ion-card-content slot="content" style="display: flex; align-items: center; justify-content: center">
            <alert-card color="danger" title="Es ist ein Fehler aufgetreten">
              {{ this.getError() }}
            </alert-card>
          </ion-card-content>
        </template>
        <template v-else>
          <ion-card-content slot="content">
            <div id="main-calculations-content">
              <template v-if="this.getAvailableCo2T < this.getNeededCo2T">
                <limit-warning>{{
                  $t("mainCalculations.warnings.co2Limit", {
                    neededCo2Mt: formatNumber(this.getNeededCo2T / 1000000, "fixed", 2, "Mio t."),
                  })
                }}</limit-warning>
              </template>
              <template v-if="this.getWaterFractionOfFreshWaterPercent >= 1">
                <limit-warning>
                  <p
                    v-html="
                      $t('mainCalculations.warnings.freshWaterLimit', {
                        waterFractionOfFreshWaterPercent: formatNumber(
                          this.getWaterFractionOfFreshWaterPercent,
                          'fixed',
                          0,
                          '%'
                        ),
                        neededWaterT: formatNumber(this.getNeededWaterT / 1000000, 'fixed', 2, 'Mio t.'),
                      })
                    "
                  ></p
                ></limit-warning>
              </template>
              <calculation-warnings :exclude="['electricityPerCo2Co2Sources']"></calculation-warnings>
              <div
                id="main-calculations-diagram"
                :class="[this.getCalculationType(), iPad() ? 'ipad' : null]"
                :style="{ 'background-size': `${this.$route.query.bs}%` }"
              >
                <main-calculations-water
                  :waterMt="this.getProductWaterMt"
                  :waterFractionOfFreshWaterPercent="this.getWaterFractionOfFreshWaterPercent"
                  :waterSource="this.getWaterSource()"
                ></main-calculations-water>
                <main-calculations-electricity></main-calculations-electricity>
                <main-calculations-heat></main-calculations-heat>
                <main-calculations-co2
                  :co2Mt="this.getProductCo2Mt"
                  :productIdentifier="this.getProductIdentifier"
                  :calculationType="this.getCalculationType"
                  :co2SourceCategoryLabel="this.getco2SourceCategoryLabel"
                ></main-calculations-co2>

                <electrolysis-water-label></electrolysis-water-label>
                <desalination-electricity-label></desalination-electricity-label>
                <electricity-own-use-label v-if="this.getCalculationType() == 'offer'"></electricity-own-use-label>
                <miscellaneous-label v-if="this.getCalculationType() == 'offer'"></miscellaneous-label>
                <electrolysis-electricity-label></electrolysis-electricity-label>
                <product-electricity-label></product-electricity-label>
                <co2-electricity-label></co2-electricity-label>
                <desalination-heat-label></desalination-heat-label>
                <product-heat-label></product-heat-label>
                <co2-heat-label></co2-heat-label>
                <hydrogen-label></hydrogen-label>
                <co2-label></co2-label>
                <output-label></output-label>

                <electrolysis-water-value :value="this.getProductWaterMt" suffix=" Mio. t"></electrolysis-water-value>
                <desalination-electricity-value
                  :value="this.getWaterElectricityTwh"
                  suffix=" TWh<sub>el</sub>"
                ></desalination-electricity-value>
                <electricity-own-use-value
                  v-if="this.getCalculationType() == 'offer'"
                  :value="this.getElectricityOwnUseTwh"
                  suffix=" TWh<sub>el</sub>"
                ></electricity-own-use-value>
                <miscellaneous-value
                  v-if="this.getCalculationType() == 'offer'"
                  :value="this.getElectricityMiscellaneousTwh"
                  suffix=" TWh<sub>el</sub>"
                ></miscellaneous-value>
                <electrolysis-electricity-value
                  :value="this.getElectrolysisElectricityTwh"
                  suffix=" TWh<sub>el</sub>"
                ></electrolysis-electricity-value>
                <product-electricity-value
                  :value="this.getProductElectricityTwh"
                  suffix=" TWh<sub>el</sub>"
                ></product-electricity-value>
                <co2-electricity-value
                  :value="this.getCo2ElectricityTwh"
                  suffix=" TWh<sub>el</sub>"
                ></co2-electricity-value>
                <desalination-heat-value
                  :value="this.getWaterHeatTwh"
                  suffix=" TWh<sub>th</sub>"
                ></desalination-heat-value>
                <product-heat-value :value="this.getProductHeatTwh" suffix=" TWh<sub>th</sub>"></product-heat-value>
                <co2-heat-value :value="this.getCo2HeatTwh" suffix=" TWh<sub>th</sub>"></co2-heat-value>
                <hydrogen-value :value="this.getProductHydrogenMt" suffix=" Mio. t"></hydrogen-value>
                <co2-value :value="this.getProductCo2Mt" suffix=" Mio. t"></co2-value>
                <output-value :value="this.getGrossProductMt" suffix=" Mio. t"></output-value>
                <calculations-legend></calculations-legend>

                <electrolysis-icon></electrolysis-icon>
                <chemical-transformation-icon></chemical-transformation-icon>

                <electrolysis-label></electrolysis-label>
                <chemical-transformation-label></chemical-transformation-label>
              </div>
            </div>
            <div id="screen-size-message">
              {{ $t("mainCalculations.warnings.screenSize") }}
            </div>
            <p v-if="this.$route.query.bs != null">{{ this.getUserAgent }}</p>
          </ion-card-content>
        </template>
      </ion-accordion>
    </ion-accordion-group>
  </ion-card>
</template>

<script>
import formatNumberMixin from "../mixins/formatNumberMixin.js";
import iPadMixin from "../mixins/iPadMixin.js";
import { mapGetters } from "vuex";
import {
  IonLabel,
  IonItem,
  IonCard,
  IonCardHeader,
  IonCardTitle,
  IonCardContent,
  IonIcon,
  IonAccordion,
  IonAccordionGroup,
} from "@ionic/vue";
import { calculatorOutline } from "ionicons/icons";
import AlertCard from "../components/AlertCard.vue";
import CalculationWarnings from "./CalculationWarnings.vue";
import LimitWarning from "./LimitWarning.vue";
import MainCalculationsWater from "../components/MainCalculationsWater.vue";
import MainCalculationsElectricity from "../components/MainCalculationsElectricity.vue";
import MainCalculationsHeat from "../components/MainCalculationsHeat.vue";
import MainCalculationsCo2 from "../components/MainCalculationsCo2.vue";
import ElectrolysisWaterValue from "../components/MainCalculations/ElectrolysisWaterValue.vue";
import DesalinationElectricityValue from "./MainCalculations/DesalinationElectricityValue.vue";
import ElectricityOwnUseValue from "../components/MainCalculations/ElectricityOwnUseValue.vue";
import MiscellaneousValue from "../components/MainCalculations/MiscellaneousValue.vue";
import ElectrolysisElectricityValue from "../components/MainCalculations/ElectrolysisElectricityValue.vue";
import ProductElectricityValue from "./MainCalculations/ProductElectricityValue.vue";
import Co2ElectricityValue from "../components/MainCalculations/Co2ElectricityValue.vue";
import DesalinationHeatValue from "../components/MainCalculations/DesalinationHeatValue.vue";
import ProductHeatValue from "../components/MainCalculations/ProductHeatValue.vue";
import Co2HeatValue from "../components/MainCalculations/Co2HeatValue.vue";
import HydrogenValue from "../components/MainCalculations/HydrogenValue.vue";
import Co2Value from "../components/MainCalculations/Co2Value.vue";
import OutputValue from "../components/MainCalculations/OutputValue.vue";
import CalculationsLegend from "../components/CalculationsLegend.vue";
import ElectrolysisWaterLabel from "../components/MainCalculations/ElectrolysisWaterLabel.vue";
import DesalinationElectricityLabel from "../components/MainCalculations/DesalinationElectricityLabel.vue";
import ElectricityOwnUseLabel from "../components/MainCalculations/ElectricityOwnUseLabel.vue";
import MiscellaneousLabel from "../components/MainCalculations/MiscellaneousLabel.vue";
import ElectrolysisElectricityLabel from "../components/MainCalculations/ElectrolysisElectricityLabel.vue";
import ProductElectricityLabel from "../components/MainCalculations/ProductElectricityLabel.vue";
import Co2ElectricityLabel from "../components/MainCalculations/Co2ElectricityLabel.vue";
import DesalinationHeatLabel from "../components/MainCalculations/DesalinationHeatLabel.vue";
import ProductHeatLabel from "../components/MainCalculations/ProductHeatLabel.vue";
import Co2HeatLabel from "../components/MainCalculations/Co2HeatLabel.vue";
import HydrogenLabel from "../components/MainCalculations/HydrogenLabel.vue";
import Co2Label from "../components/MainCalculations/Co2Label.vue";
import OutputLabel from "../components/MainCalculations/OutputLabel.vue";
import ElectrolysisIcon from "../components/MainCalculations/ElectrolysisIcon.vue";
import ChemicalTransformationIcon from "../components/MainCalculations/ChemicalTransformationIcon.vue";
import ElectrolysisLabel from "../components/MainCalculations/ElectrolysisLabel.vue";
import ChemicalTransformationLabel from "../components/MainCalculations/ChemicalTransformationLabel.vue";
export default {
  components: {
    AlertCard,
    CalculationWarnings,
    IonLabel,
    IonItem,
    IonCard,
    IonCardHeader,
    IonCardTitle,
    IonCardContent,
    IonIcon,
    IonAccordion,
    IonAccordionGroup,
    LimitWarning,
    MainCalculationsWater,
    MainCalculationsElectricity,
    MainCalculationsHeat,
    MainCalculationsCo2,
    ElectrolysisWaterValue,
    DesalinationElectricityValue,
    ElectricityOwnUseValue,
    MiscellaneousValue,
    ElectrolysisElectricityValue,
    ProductElectricityValue,
    Co2ElectricityValue,
    DesalinationHeatValue,
    ProductHeatValue,
    Co2HeatValue,
    HydrogenValue,
    Co2Value,
    OutputValue,
    CalculationsLegend,
    ElectrolysisWaterLabel,
    DesalinationElectricityLabel,
    ElectricityOwnUseLabel,
    MiscellaneousLabel,
    ElectrolysisElectricityLabel,
    ProductElectricityLabel,
    Co2ElectricityLabel,
    DesalinationHeatLabel,
    ProductHeatLabel,
    Co2HeatLabel,
    HydrogenLabel,
    Co2Label,
    OutputLabel,
    ElectrolysisIcon,
    ElectrolysisLabel,
    ChemicalTransformationIcon,
    ChemicalTransformationLabel,
  },
  computed: {
    ...mapGetters("settings", ["getCalculationType", "getWaterSource", "getProductId", "getCo2SourceCategoryId"]),
    ...mapGetters({ getProductById: "products/getById" }),
    ...mapGetters({ getCo2SourceCategoryById: "co2SourceCategories/getById" }),
    ...mapGetters("errors", ["getError"]),
    ...mapGetters("calculations", ["getWarnings", "getNeededWaterT", "getAvailableCo2T", "getNeededCo2T"]),
    getElectricityMiscellaneousTwh: function () {
      if (typeof this.$store.getters["calculations/getElectricityMiscellaneousTwh"] === "number") {
        return this.$store.getters["calculations/getElectricityMiscellaneousTwh"].toFixed(2);
      } else {
        return "-";
      }
    },
    getElectricityOwnUseTwh: function () {
      if (typeof this.$store.getters["calculations/getElectricityOwnUseTwh"] === "number") {
        return this.$store.getters["calculations/getElectricityOwnUseTwh"].toFixed(2);
      } else {
        return "-";
      }
    },
    getWaterElectricityTwh: function () {
      if (typeof this.$store.getters["calculations/getWaterElectricityTwh"] === "number") {
        return this.$store.getters["calculations/getWaterElectricityTwh"].toFixed(2);
      } else {
        return "-";
      }
    },
    getElectrolysisElectricityTwh: function () {
      if (typeof this.$store.getters["calculations/getElectrolysisElectricityTwh"] === "number") {
        return this.$store.getters["calculations/getElectrolysisElectricityTwh"].toFixed(2);
      } else {
        return "-";
      }
    },
    getProductIdentifier: function () {
      return this.getProductById(this.getProductId())?.attributes?.identifier;
    },
    getco2SourceCategoryLabel: function () {
      return this.getCo2SourceCategoryById(this.getCo2SourceCategoryId())?.attributes.label_de;
    },
    getProductElectricityTwh: function () {
      if (typeof this.$store.getters["calculations/getProductElectricityTwh"] === "number") {
        return this.$store.getters["calculations/getProductElectricityTwh"].toFixed(2);
      } else {
        return "-";
      }
    },
    getCo2ElectricityTwh: function () {
      if (typeof this.$store.getters["calculations/getCo2ElectricityTwh"] === "number") {
        return this.$store.getters["calculations/getCo2ElectricityTwh"].toFixed(2);
      } else {
        return "-";
      }
    },
    getProductHydrogenMt: function () {
      if (typeof this.$store.getters["calculations/getProductHydrogenT"] === "number") {
        return (this.$store.getters["calculations/getProductHydrogenT"] / 1000000).toFixed(2);
      } else {
        return "-";
      }
    },
    getGrossProductMt: function () {
      if (typeof this.$store.getters["calculations/getGrossProductT"] === "number") {
        return (this.$store.getters["calculations/getGrossProductT"] / 1000000).toFixed(2);
      } else {
        return "-";
      }
    },
    getProductHeatTwh: function () {
      if (typeof this.$store.getters["calculations/getProductHeatTwh"] === "number") {
        return this.$store.getters["calculations/getProductHeatTwh"].toFixed(2);
      } else {
        return "-";
      }
    },
    getProductCo2Mt: function () {
      if (typeof this.$store.getters["calculations/getProductCo2T"] === "number") {
        return (this.$store.getters["calculations/getProductCo2T"] / 1000000).toFixed(2);
      } else {
        return "-";
      }
    },
    getCo2HeatTwh: function () {
      if (typeof this.$store.getters["calculations/getCo2HeatTwh"] === "number") {
        return this.$store.getters["calculations/getCo2HeatTwh"].toFixed(2);
      } else {
        return "-";
      }
    },
    getWaterHeatTwh: function () {
      if (typeof this.$store.getters["calculations/getWaterHeatTwh"] === "number") {
        return this.$store.getters["calculations/getWaterHeatTwh"].toFixed(2);
      } else {
        return "-";
      }
    },
    getAvailableWaterMt: function () {
      if (typeof this.$store.getters["calculations/getAvailableWaterT"] === "number") {
        return (this.$store.getters["calculations/getAvailableWaterT"] / 1000000).toFixed(2);
      } else {
        return "-";
      }
    },
    getProductWaterMt: function () {
      if (typeof this.$store.getters["calculations/getProductWaterT"] === "number") {
        return (this.$store.getters["calculations/getProductWaterT"] / 1000000).toFixed(2);
      } else {
        return "-";
      }
    },
    getWaterFractionOfFreshWaterPercent: function () {
      if (typeof this.$store.getters["calculations/getWaterFractionOfFreshWaterPercent"] === "number") {
        return (this.$store.getters["calculations/getWaterFractionOfFreshWaterPercent"] * 100).toFixed(4);
      } else {
        return "-";
      }
    },
    getUserAgent: function () {
      return navigator.userAgent;
    },
  },
  setup() {
    return { calculatorOutline };
  },
  mixins: [iPadMixin, formatNumberMixin],
};
</script>

<style lang="scss" scoped>
ion-card-content {
  padding: 0;
}
#main-calculations-diagram {
  background-position: left top;
  background-size: 100% auto;
  height: 470px;
  background-repeat: no-repeat;
  position: relative;
}

#main-calculations-diagram.demand {
  background-image: url("~@/assets/images/main-calculations-demand-background.svg");
}

#main-calculations-diagram.offer {
  background-image: url("~@/assets/images/main-calculations-offer-background.svg");
}

@media screen and (max-width: 575.99px) {
  #main-calculations-content {
    background: none;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  #main-calculations-content {
    display: none;
  }

  #screen-size-message {
    text-align: center;
  }
}

@media screen and (min-width: 576px) {
  #main-calculations-diagram {
    height: 570px;
  }

  #screen-size-message {
    display: none;
  }
}
@media screen and (min-width: 768px) {
  #main-calculations-diagram {
    height: 750px;
    &.ipad {
      background-size: 101.75% auto;
    }
  }
}
@media screen and (min-width: 992px) {
  #main-calculations-diagram {
    height: 990px;

    &.ipad {
      background-size: 101.25% auto;
    }
  }
}
@media screen and (min-width: 1200px) {
  #main-calculations-diagram {
    height: 1160px;
  }
}
</style>
