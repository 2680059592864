import { createI18n } from 'vue-i18n'

import { de } from '@/locales/de.yml'
import { en } from '@/locales/en.yml'
const messages = { de, en }


export const getLocale = () => { return window.location.pathname.split("/")[1] || "de" }

export const i18n = createI18n({
  locale: 'de',
  warnHtmlInMessage: 'off',
  fallbackLocale: 'en',
  messages
})

export default i18n
