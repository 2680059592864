<template>
  <ion-card id="water-sources-settings">
    <ion-accordion-group value="open">
      <ion-accordion value="open">
        <ion-item slot="header">
          <ion-card-header>
            <ion-card-title>
              <ion-icon :icon="waterOutline"></ion-icon>
              <ion-label>{{ $t("waterSources.title") }}</ion-label>
            </ion-card-title>
          </ion-card-header>
        </ion-item>

        <ion-card-content slot="content">
          <calculation-warnings
            :filter="['hydrogen', 'fresh_water', 'heat_water', 'salt_water']"
          ></calculation-warnings>
          <water-sources-radio-buttons></water-sources-radio-buttons>
        </ion-card-content>
      </ion-accordion>
    </ion-accordion-group>
  </ion-card>
</template>

<script>
import CalculationWarnings from "./CalculationWarnings.vue";
import WaterSourcesRadioButtons from "./WaterSources/WaterSourcesRadioButtons.vue";
import {
  IonAccordion,
  IonAccordionGroup,
  IonCard,
  IonCardTitle,
  IonCardHeader,
  IonCardContent,
  IonItem,
  IonIcon,
  IonLabel,
} from "@ionic/vue";
import { waterOutline } from "ionicons/icons";
export default {
  components: {
    CalculationWarnings,
    IonAccordion,
    IonAccordionGroup,
    IonCard,
    IonCardTitle,
    IonCardHeader,
    IonCardContent,
    IonItem,
    IonIcon,
    IonLabel,
    WaterSourcesRadioButtons,
  },
  setup() {
    return { waterOutline };
  },
};
</script>

<style lang="scss" scoped></style>
