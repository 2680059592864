<template>
  <ion-grid>
    <ion-row>
      <ion-col size="12" size-md="6" size-lg="9">
        <h1>{{ $t("mainIntro.heading") }}</h1>
        <div v-html="$t('mainIntro.text')"></div>
      </ion-col>
      <ion-col size="12" size-md="6" size-lg="3" class="display-flex ion-justify-content-center ion-align-items-center">
        <dechema-logo></dechema-logo>
      </ion-col>
    </ion-row>
  </ion-grid>
</template>

<script>
import { IonGrid, IonRow, IonCol } from "@ionic/vue";
import DechemaLogo from "../components/DechemaLogo.vue";

export default { components: { IonGrid, IonRow, IonCol, DechemaLogo } };
</script>

<style lang="scss" scoped>
@media screen and (min-width: 768px) {
  ion-grid {
    margin-top: 2rem;
    margin-bottom: 2rem;
  }
}

@media screen and (min-width: 992px) {
  ion-grid {
    margin-top: 4rem;
    margin-bottom: 4rem;
  }
}
</style>
