<template>
  <ion-card>
    <ion-accordion-group value="open">
      <ion-accordion value="open">
        <ion-item slot="header">
          <ion-card-header>
            <ion-card-title>
              <ion-icon :icon="settingsOutline"></ion-icon>
              <ion-label>{{ $t("generalSettings.title") }}</ion-label>
            </ion-card-title>
          </ion-card-header>
        </ion-item>

        <ion-card-content slot="content">
          <calculation-warnings :filter="['productRequirement', 'neededResources', 'product']"></calculation-warnings>
          <ion-grid>
            <ion-row>
              <ion-col size="12" size-lg="9">
                <ion-row>
                  <ion-col>
                    <ion-label position="floating">{{ $t("generalSettings.labels.country") }}</ion-label>
                    <country-select></country-select>
                  </ion-col>
                  <ion-col>
                    <ion-label position="floating">{{ $t("generalSettings.labels.year") }}</ion-label>
                    <year-select></year-select>
                  </ion-col>
                  <ion-col>
                    <ion-label position="floating">{{ $t("generalSettings.labels.product") }}</ion-label>
                    <product-select></product-select>
                  </ion-col>
                </ion-row>
                <ion-row>
                  <ion-col>
                    <product-description></product-description>
                  </ion-col>
                </ion-row>
              </ion-col>
              <ion-col size="12" size-lg="3">
                <calculation-type-select></calculation-type-select>
              </ion-col>
            </ion-row>
          </ion-grid>
        </ion-card-content>
      </ion-accordion>
    </ion-accordion-group>
  </ion-card>
</template>

<script>
import { IonCard, IonCardHeader, IonCardTitle, IonCardContent } from "@ionic/vue";
import CalculationWarnings from "./CalculationWarnings.vue";
import CalculationTypeSelect from "./GeneralSettings/CalculationTypeSelect.vue";
import CountrySelect from "./GeneralSettings/CountrySelect.vue";
import ProductSelect from "./GeneralSettings/ProductSelect.vue";
import YearSelect from "./GeneralSettings/YearSelect.vue";
import ProductDescription from "./GeneralSettings/ProductDescription.vue";
import { IonGrid, IonRow, IonCol, IonItem, IonLabel, IonIcon, IonAccordion, IonAccordionGroup } from "@ionic/vue";
import { settingsOutline } from "ionicons/icons";
export default {
  components: {
    IonCard,
    IonCardHeader,
    IonCardTitle,
    IonCardContent,
    IonItem,
    IonLabel,
    IonGrid,
    IonRow,
    IonCol,
    IonIcon,
    IonAccordion,
    IonAccordionGroup,
    CalculationTypeSelect,
    CalculationWarnings,
    CountrySelect,
    YearSelect,
    ProductSelect,
    ProductDescription,
  },
  setup() {
    return { settingsOutline };
  },
};
</script>

<style lang="scss" scoped></style>
