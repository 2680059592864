export default {
  namespaced: true,
  state: {
    calculationTypes: [],
  },
  getters: {
    getAll: (state) => () => {
      return state.calculationTypes;
    },
    getById: (state) => (id) => {
      return state.calculationTypes.find((calculationType) => calculationType.id === id);
    },
    getDefaultCalculationType: (state) => () => {
      return state.calculationTypes.find((calculationType) => calculationType.attributes.identifier === "offer");
    },
  },
  actions: {
    async fetchAll({ commit }) {
      try {
        let data = [
          { id: 1, attributes: { identifier: "offer", label_de: "Angebot", label_en: "Offer" } },
          { id: 2, attributes: { identifier: "demand", label_de: "Nachfrage", label_en: "Demand" } },
        ];
        commit("SET_CALCULATION_TYPES", data);
      } catch (error) {
        console.log(error);
      }
    },
  },
  mutations: {
    SET_CALCULATION_TYPES(state, calculationTypes) {
      state.calculationTypes = calculationTypes;
    },
  },
};
