<template>
  <div class="locale-changer">
    <a :href="`/${lang.locale}`" v-for="(lang, i) in langs" :key="`lang-${i}`" class="locale">
      <span :class="`fi fi-${lang.flag}`"></span>
      <span class="locale-label">{{ lang.locale }}</span>
    </a>
  </div>
</template>

<script>
export default {
  data() {
    return {
      langs: [
        { locale: "de", flag: "de" },
        { locale: "en", flag: "gb" },
      ],
    };
  },
};
</script>
<style lang="scss" scoped>
a {
  margin-right: 0.5rem;
}

.locale {
  border-right: 1px solid var(--ion-color-primary-contrast);
  padding-right: 0.33rem;
}

.locale:last-child {
  border-right: none;
  padding-right: none;
}

.locale-label {
  color: var(--ion-color-primary-contrast);
  margin-left: 0.25rem;
}
</style>
