<template>
  <ion-select interface="popover" placeholder="Bitte wählen Sie ein Land aus" v-model="countryId">
    <ion-select-option v-for="country in countries" :key="country.id" :value="country.id">
      {{ country.attributes[`label_${$i18n.locale}`] }}
    </ion-select-option>
  </ion-select>
</template>

<script>
import { mapState, mapGetters } from "vuex";
import { mapFields } from "vuex-map-fields";
import { IonSelect, IonSelectOption } from "@ionic/vue";
export default {
  components: {
    IonSelect,
    IonSelectOption,
  },
  computed: {
    ...mapState("countries", {
      countries: (state) => state.countries,
    }),
    ...mapGetters("countries", ["getAll", "getById", "getByIdentifier", "getDefaultCountry"]),
    ...mapFields("settings", ["countryId"]),
  },
  mounted: async function () {
    await this.$store.dispatch("countries/fetchAll");
    this.$store.commit("settings/setCountryId", { id: this.getDefaultCountry().id });
  },
};
</script>

<style lang="scss" scoped></style>
