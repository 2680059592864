<template>
  <ion-select interface="popover" placeholder="Bitte wählen Sie ein Jahr aus" v-model="yearId">
    <ion-select-option v-for="year in years" :key="year.id" :value="year.id">
      {{ year.attributes.label }}
    </ion-select-option>
  </ion-select>
</template>

<script>
import { mapState, mapGetters } from "vuex";
import { mapFields } from "vuex-map-fields";
import { IonSelect, IonSelectOption } from "@ionic/vue";
export default {
  components: {
    IonSelect,
    IonSelectOption,
  },
  computed: {
    ...mapState("years", {
      years: (state) => state.years,
    }),
    ...mapGetters("years", ["getDefaultYear"]),
    ...mapFields("settings", ["yearId"]),
  },
  mounted: async function () {
    await this.$store.dispatch("years/fetchAll");
    this.$store.commit("settings/setYearId", { id: this.getDefaultYear().id });
  },
};
</script>

<style lang="scss" scoped></style>
