import axios from "axios";

export default {
  namespaced: true,
  state: {
    years: [],
  },
  getters: {
    getAll: (state) => () => {
      return state.years;
    },
    getById: (state) => (id) => {
      return state.years.find((year) => year.id === id);
    },
    getDefaultYear: (state) => () => {
      return state.years.find((year) => year.attributes.label === "2030");
    },
  },
  actions: {
    async fetchAll({ commit }) {
      try {
        const qs = require('qs');
        const query = qs.stringify({
          sort_by: 'label',
          sort_direction: 'asc',
        })
        const data = await axios.get(`${process.env.VUE_APP_BACKEND_BASE_URL}/years?${query}`, {
          headers: {
            "Content-Type": "application/json",
            "Accept": "application/json",
            Authorization: `Bearer ${process.env.VUE_APP_BACKEND_API_TOKEN}`,
          },
        });
        commit("SET_YEARS", data.data.data);
      } catch (error) {
        console.log(error);
      }
    },
  },
  mutations: {
    SET_YEARS(state, years) {
      state.years = years;
    },
  },
};
