<template>
  <ion-toast :is-open="open" :message="error" :duration="2000" color="danger"> </ion-toast>
</template>

<script>
import { IonToast } from "@ionic/vue";
import { mapState } from "vuex";

export default {
  components: { IonToast },
  computed: {
    ...mapState("errors", ["open", "error"]),
  },
  methods: {
    resetError() {
      this.$store.commit("errors/RESET", { root: true });
    },
  },
};
</script>
