<template>
  <calculations-card :hideHeader="true">
    <ion-grid>
      <ion-row>
        <ion-col size="12" size-sm="4">
          <hr class="arrow electricity-arrow" />
          {{ $t("calculationsLegend.labels.electricity") }}
        </ion-col>
        <ion-col size="12" size-sm="4">
          <hr class="arrow heat-arrow" />
          {{ $t("calculationsLegend.labels.heat") }}
        </ion-col>
        <ion-col size="12" size-sm="4">
          <hr class="arrow mass-arrow" />
          {{ $t("calculationsLegend.labels.mass") }}
        </ion-col>
      </ion-row>
    </ion-grid>
  </calculations-card>
</template>

<script>
import CalculationsCard from "../components/MainCalculations/CalculationsCard.vue";
import { IonGrid, IonRow, IonCol } from "@ionic/vue";
export default {
  components: {
    IonGrid,
    IonRow,
    IonCol,
    CalculationsCard,
  },
};
</script>

<style lang="scss" scoped>
hr.arrow {
  border-top: 3px;
}

hr.electricity-arrow {
  border-style: dotted;
  border-color: #7fc818;
}
hr.heat-arrow {
  border-style: dashed;
  border-color: #de5619;
}
hr.mass-arrow {
  border-style: solid;
  border-color: #005983;
}

ion-card {
  top: 110px;
  height: 155px;
}

ion-item {
}

@media screen and (min-width: 576px) {
  ion-card {
    top: 465px;
    height: 70px;
    width: 450px;
  }
}
@media screen and (min-width: 768px) {
  ion-card {
    top: 625px;
    height: 90px;
    width: 630px;
  }
}
@media screen and (min-width: 992px) {
  ion-card {
    top: 860px;
    height: 90px;
    width: 870px;
  }
}
@media screen and (min-width: 1200px) {
  ion-card {
    top: 1030px;
    height: 90px;
    width: 1050px;
  }
}
</style>
