<template>
  <alert-card v-if="Object.keys(this.warnings).length > 0" color="danger">
    <ul>
      <template v-for="(messages, service) in warnings" :key="service">
        <li v-for="(message, i) in messages" :key="i">
          {{ message }}
        </li>
      </template>
    </ul>
  </alert-card>
</template>

<script>
import { mapGetters } from "vuex";
import AlertCard from "./AlertCard.vue";
export default {
  components: {
    AlertCard,
  },
  props: {
    filter: {
      type: Array,
      default: () => [],
    },
    exclude: {
      type: Array,
      default: () => [],
    },
  },
  computed: {
    ...mapGetters("calculations", ["getWarnings"]),
    warningKeys: function () {
      return Object.keys(this.getWarnings());
    },
    warnings: function () {
      if (this.filter.length > 0) {
        return this.warningKeys
          .filter((key) => this.filter.includes(key))
          .reduce((cur, key) => {
            return Object.assign(cur, { [key]: this.getWarnings()[key] });
          }, {});
      } else if (this.exclude.length > 0) {
        return this.warningKeys
          .filter((key) => !this.exclude.includes(key))
          .reduce((cur, key) => {
            return Object.assign(cur, { [key]: this.getWarnings()[key] });
          }, {});
      } else {
        return this.getWarnings();
      }
    },
  },
};
</script>

<style lang="scss" scoped>
ul {
  padding-left: inherit;
  li {
    list-style-type: none;
  }
}
</style>
