<template>
  <ion-card>
    <ion-accordion-group value="open">
      <ion-accordion value="open">
        <ion-item slot="header">
          <ion-card-header>
            <ion-card-title>
              <ion-icon :icon="barChartOutline"></ion-icon>
              <ion-label>{{ $t("mainVisualization.title") }}</ion-label>
            </ion-card-title>
          </ion-card-header>
        </ion-item>

        <ion-card-content slot="content">
          <ion-grid>
            <ion-row>
              <ion-col v-if="this.getCalculationType() == 'offer'" size="12" size-md="6">
                <h2>
                  {{
                    $t("mainVisualization.electricityPotentialChart.heading", {
                      year: this.getYear?.label,
                      electricityPotential: formatNumber(this.getData.electricityPotentialTwh, "fixed", 2),
                    })
                  }}
                </h2>
                <donut-chart></donut-chart>
              </ion-col>
              <ion-col size="12" size-md="6">
                <h2>
                  {{
                    $t("mainVisualization.productChart.heading", {
                      product: (this.product?.attributes || {})[`label_${$i18n.locale}`],
                    })
                  }}
                </h2>
                <bar-chart></bar-chart>
              </ion-col>
            </ion-row>
          </ion-grid>
        </ion-card-content>
      </ion-accordion>
    </ion-accordion-group>
  </ion-card>
</template>

<script>
import formatNumberMixin from "../mixins/formatNumberMixin.js";
import { mapGetters } from "vuex";
import {
  IonCard,
  IonCardHeader,
  IonCardTitle,
  IonCardContent,
  IonGrid,
  IonRow,
  IonCol,
  IonIcon,
  IonAccordion,
  IonAccordionGroup,
  IonLabel,
  IonItem,
} from "@ionic/vue";
import { barChartOutline } from "ionicons/icons";
import BarChart from "../components/MainVisualization/BarChart.vue";
import DonutChart from "../components/MainVisualization/DonutChart.vue";

export default {
  components: {
    IonCard,
    IonCardHeader,
    IonCardTitle,
    IonCardContent,
    IonGrid,
    IonRow,
    IonCol,
    BarChart,
    DonutChart,
    IonIcon,
    IonAccordion,
    IonAccordionGroup,
    IonLabel,
    IonItem,
  },
  computed: {
    ...mapGetters("calculations", ["getYear", "getData"]),
    ...mapGetters("settings", ["getCalculationType"]),
    product() {
      return this.$store.getters["products/getById"](this.$store.state.settings.productId);
    },
  },
  setup() {
    return { barChartOutline };
  },
  mixins: [formatNumberMixin],
};
</script>

<style lang="scss" scoped></style>
