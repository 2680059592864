<template>
  <calculation-value :prefix="prefix" :value="value" :suffix="suffix" id="desalination-value"></calculation-value>
</template>

<script>
import CalculationValue from "./CalculationValue.vue";
export default {
  components: {
    CalculationValue,
  },
  props: {
    prefix: {
      type: String,
      required: false,
    },
    value: {
      type: String,
      required: true,
    },
    suffix: {
      type: String,
      required: false,
    },
  },
};
</script>

<style lang="scss" scoped>
#desalination-value {
  top: 285px;
  left: 548px;
}

@media screen and (min-width: 576px) {
  #desalination-value {
    top: 143px;
    left: 256px;
  }
}
@media screen and (min-width: 768px) {
  #desalination-value {
    top: 196px;
    left: 348px;
  }
}
@media screen and (min-width: 992px) {
  #desalination-value {
    top: 266px;
    left: 471px;
  }
}
@media screen and (min-width: 1200px) {
  #desalination-value {
    top: 316px;
    left: 564px;
  }
}
</style>
