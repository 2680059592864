<template>
  <ion-checkbox slot="start" v-model="considerGrossElectricityConsumption"> </ion-checkbox>
</template>

<script>
import { mapFields } from "vuex-map-fields";
import { IonCheckbox } from "@ionic/vue";
export default {
  components: {
    IonCheckbox,
  },
  computed: {
    ...mapFields("settings", ["considerGrossElectricityConsumption"]),
  },
  mounted: async function () {
    this.$store.commit("settings/setConsiderGrossElectricityConsumption", true);
  },
};
</script>

<style lang="scss" scoped></style>
