<template>
  <calculations-card :title="$t('mainCalculationsElectricity.title')" icon="flashOutline">
    <div v-if="this.getCalculationType() == 'offer'">
      <div>
        {{
          $t("mainCalculationsElectricity.explanations.offer.availableElectricity", {
            processElectricityTwh: this.getProcessElectricityTwh < 0 ? 0 : this.getProcessElectricityTwh,
            electricityPotentialTwh: this.getElectricityPotentialTwh,
          })
        }}
      </div>
      <div>
        <electricity-input-chart></electricity-input-chart>
      </div>
    </div>
    <div v-if="this.getCalculationType() == 'demand'">
      <div>
        {{
          $t("mainCalculationsElectricity.explanations.demand.neededElectricity", {
            processElectricityTwh: this.getProcessElectricityTwh,
          })
        }}
      </div>
    </div>
  </calculations-card>
</template>

<script>
import { mapGetters } from "vuex";
import CalculationsCard from "../components/MainCalculations/CalculationsCard.vue";
import ElectricityInputChart from "../components/MainCalculations/ElectricityInputChart.vue";
export default {
  components: {
    CalculationsCard,
    ElectricityInputChart,
  },
  computed: {
    ...mapGetters("calculations", ["getElectricityPotentials"]),
    ...mapGetters("settings", ["getCalculationType"]),
    getElectricityPotentialTwh: function () {
      if (isNaN(this.$store.getters["calculations/getElectricityPotentialTwh"])) {
        return "-";
      } else {
        return this.$store.getters["calculations/getElectricityPotentialTwh"].toFixed(2);
      }
    },
    getProcessElectricityTwh: function () {
      if (isNaN(this.$store.getters["calculations/getProcessElectricityTwh"])) {
        return "-";
      } else {
        return this.$store.getters["calculations/getProcessElectricityTwh"].toFixed(2);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
ion-card {
  top: 110px;
  height: 155px;
  * {
    font-size: 75%;
  }
}

ion-item {
  padding: 0;
  font-size: 75%;
}

@media screen and (min-width: 576px) {
  ion-card {
    top: 110px;
    margin-bottom: 0px;
    height: 155px;
  }
}
@media screen and (min-width: 768px) {
  ion-card {
    top: 160px;
    margin-bottom: 5px;
    height: 210px;
    * {
      font-size: 80%;
    }
  }
}
@media screen and (min-width: 992px) {
  ion-card {
    top: 210px;
    height: 290px;
    * {
      font-size: 90%;
    }
  }
}
@media screen and (min-width: 1200px) {
  ion-card {
    top: 260px;
    height: 340px;
    * {
      font-size: 100%;
    }
  }
}
</style>
