<template>
  <calculation-value :prefix="prefix" :value="value" :suffix="suffix" id="electrolysis-water-value"></calculation-value>
</template>

<script>
import CalculationValue from "./CalculationValue.vue";
export default {
  components: {
    CalculationValue,
  },
  props: {
    prefix: {
      type: String,
      required: false,
    },
    value: {
      type: String,
      required: true,
    },
    suffix: {
      type: String,
      required: false,
    },
  },
};
</script>

<style lang="scss" scoped>
@media screen and (min-width: 576px) {
  #electrolysis-water-value {
    top: 39px;
    left: 384px;
  }
}
@media screen and (min-width: 768px) {
  #electrolysis-water-value {
    top: 53px;
    left: 522px;
  }
}
@media screen and (min-width: 992px) {
  #electrolysis-water-value {
    top: 72px;
    left: 708px;
  }
}
@media screen and (min-width: 1200px) {
  #electrolysis-water-value {
    top: 85px;
    left: 846px;
  }
}
</style>
