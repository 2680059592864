<template>
  <div>
    <ion-select interface="popover" placeholder="Bitte wählen Sie ein Produkt aus" v-model="productId">
      <template v-for="productCategory in productCategories" :key="`product-category-${productCategory.id}`">
        <ion-select-option disabled class="ion-select-option-group">{{
          productCategory.attributes[`label_${$i18n.locale}`]
        }}</ion-select-option>
        <ion-select-option
          v-for="product in filterPublished(productCategory.attributes.products.data)"
          :key="`product-${product.id}`"
          :value="product.id"
          >{{ product.attributes[`label_${$i18n.locale}`] }}</ion-select-option
        >
      </template>
    </ion-select>
  </div>
</template>

<script>
import { mapState, mapGetters } from "vuex";
import { mapFields } from "vuex-map-fields";
import { IonSelect, IonSelectOption } from "@ionic/vue";
export default {
  components: {
    IonSelect,
    IonSelectOption,
  },
  computed: {
    ...mapState("productCategories", {
      productCategories: (state) => state.productCategories,
    }),
    ...mapGetters("productCategories", ["getAll", "getById", "getByName"]),
    ...mapGetters("products", ["getDefaultProduct"]),
    ...mapFields("settings", ["productId"]),
  },
  mounted: async function () {
    await this.$store.dispatch("productCategories/fetchAll");
    await this.$store.dispatch("products/fetchAll");
    this.$store.commit("settings/setProductId", { id: this.getDefaultProduct().id });
  },
  methods: {
    filterPublished(items) {
      return items.filter((item) => {
        return item.attributes.published_at != null;
      });
    },
  },
};
</script>

<style lang="scss" scoped></style>
