<template>
  <h2>Energieströme</h2>

  <h3>Strom für Prozesse</h3>

  <table class="w-100 table-striped border">
    <tbody>
      <tr class="ion-border-medium ion-border-bottom" v-if="'processElectricityTwh' in this.getData">
        <th class="process_electricity_twh">{{ this.getMeta.attributeNames.processElectricityTwh }}</th>
        <td class="process_electricity_twh number">{{ formatNumber(this.getData.processElectricityTwh) }}</td>
        <td class="unit">TWh</td>
      </tr>

      <tr v-if="'waterElectricityTwh' in this.getData">
        <th class="water_electricity_twh font-weight-normal pl-1">
          {{ this.getMeta.attributeNames.waterElectricityTwh }}
        </th>
        <td class="water_electricity_twh font-weight-normal pl-1">
          {{ formatNumber(this.getData.waterElectricityTwh) }}
        </td>
        <td class="unit">TWh</td>
      </tr>

      <tr v-if="'co2ElectricityTwh' in this.getData">
        <th class="co2_electricity_twh font-weight-normal pl-1">{{ this.getMeta.attributeNames.co2ElectricityTwh }}</th>
        <td class="co2_electricity_twh font-weight-normal pl-1">{{ formatNumber(this.getData.co2ElectricityTwh) }}</td>
        <td class="unit">TWh</td>
      </tr>

      <tr v-if="'productElectricityTwh' in this.getData">
        <th class="product_electricity_twh font-weight-normal pl-1">
          {{ this.getMeta.attributeNames.productElectricityTwh }}
        </th>
        <td class="product_electricity_twh font-weight-normal pl-1">
          {{ formatNumber(this.getData.productElectricityTwh) }}
        </td>
        <td class="unit">TWh</td>
      </tr>

      <tr v-if="'electrolysisElectricityTwh' in this.getData">
        <th class="electrolysis_electricity_twh font-weight-normal pl-1">
          {{ this.getMeta.attributeNames.electrolysisElectricityTwh }}
        </th>
        <td class="electrolysis_electricity_twh font-weight-normal pl-1">
          {{ formatNumber(this.getData.electrolysisElectricityTwh) }}
        </td>
        <td class="unit">TWh</td>
      </tr>
    </tbody>
  </table>

  <h3>Wärme</h3>

  <table class="w-100 table-striped border">
    <tbody>
      <tr v-if="'co2HeatTwh' in this.getData">
        <th class="co2_heat_twh">{{ this.getMeta.attributeNames.co2HeatTwh }}</th>
        <td class="co2_heat_twh number">{{ formatNumber(this.getData.co2HeatTwh) }}</td>
        <td class="unit">TWh</td>
      </tr>

      <tr v-if="'productHeatTwh' in this.getData">
        <th class="product_heat_twh">{{ this.getMeta.attributeNames.productHeatTwh }}</th>
        <td class="product_heat_twh number">{{ formatNumber(this.getData.productHeatTwh) }}</td>
        <td class="unit">TWh</td>
      </tr>

      <tr v-if="'waterHeatTwh' in this.getData">
        <th class="water_heat_twh">{{ this.getMeta.attributeNames.waterHeatTwh }}</th>
        <td class="water_heat_twh number">{{ formatNumber(this.getData.waterHeatTwh) }}</td>
        <td class="unit">TWh</td>
      </tr>

      <tr class="ion-border-medium ion-border-top" v-if="'heatTwh' in this.getData">
        <th class="heat_twh">{{ this.getMeta.attributeNames.heatTwh }}</th>
        <td class="heat_twh number">{{ formatNumber(this.getData.heatTwh) }}</td>
        <td class="unit">TWh</td>
      </tr>
    </tbody>
  </table>

  <h2>Massenströme</h2>

  <table class="w-100 table-striped border">
    <tbody>
      <tr v-if="'productWaterT' in this.getData">
        <th class="product_water_t">{{ this.getMeta.attributeNames.productWaterT }}</th>
        <td class="product_water_t number">{{ formatNumber(this.getData.productWaterT) }}</td>
        <td class="unit">t</td>
      </tr>

      <tr v-if="'productCo2T' in this.getData">
        <th class="product_co2_t">{{ this.getMeta.attributeNames.productCo2T }}</th>
        <td class="product_co2_t number">{{ formatNumber(this.getData.productCo2T) }}</td>
        <td class="unit">t</td>
      </tr>

      <tr v-if="'productHydrogenT' in this.getData">
        <th class="product_hydrogen_t">{{ this.getMeta.attributeNames.productHydrogenT }}</th>
        <td class="product_hydrogen_t number">{{ formatNumber(this.getData.productHydrogenT) }}</td>
        <td class="unit">t</td>
      </tr>

      <tr v-if="'netProductT' in this.getData">
        <th class="net_product_t">{{ this.getMeta.attributeNames.netProductT }}</th>
        <td class="net_product_t number">{{ formatNumber(this.getData.netProductT) }}</td>
        <td class="unit">t</td>
      </tr>

      <tr v-if="'netProductT' in this.getData">
        <th class="net_product_t">{{ this.getMeta.attributeNames.netProductT }}</th>
        <td class="net_product_t number">{{ formatNumber(this.getData.netProductT) }}</td>
        <td class="unit">t</td>
      </tr>

      <tr v-if="'calculationType' in this.getData">
        <th class="calculation_type">{{ this.getMeta.attributeNames.calculationType }}</th>
        <td class="calculation_type number">{{ formatNumber(this.getData.calculationType) }}</td>
        <td class="unit">t</td>
      </tr>
    </tbody>
  </table>
</template>

<script>
import { mapGetters } from "vuex";
import formatNumberMixin from "../../mixins/formatNumberMixin.js";
export default {
  computed: {
    ...mapGetters("calculations", ["getMeta", "getData"]),
  },
  mixins: [formatNumberMixin],
};
</script>

<style lang="scss" scoped>
h2,
h3 {
  margin-bottom: 1rem;
}

table {
  margin-bottom: 1rem;
}
</style>
