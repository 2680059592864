import { createStore, createLogger } from "vuex";
import calculationTypes from "./modules/calculationTypes";
import co2SourceCategories from "./modules/co2SourceCategories";
import co2Sources from "./modules/co2Sources";
import countries from "./modules/countries";
import errors from "./modules/errors";
import electricityTypes from "./modules/electricityTypes";
import productCategories from "./modules/productCategories";
import productRequirements from "./modules/productRequirements";
import products from "./modules/products";
import settings from "./modules/settings";
import calculations from "./modules/calculations";
import calculationsMany from "./modules/calculationsMany";
import waterSources from "./modules/waterSources";
import years from "./modules/years";

const debug = process.env.NODE_ENV !== "production";

export default createStore({
  modules: {
    co2Sources,
    co2SourceCategories,
    countries,
    errors,
    electricityTypes,
    productCategories,
    productRequirements,
    products,
    settings,
    calculations,
    calculationsMany,
    calculationTypes,
    waterSources,
    years
  },
  strict: debug,
  plugins: debug ? [createLogger()] : [],
});
