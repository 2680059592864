<template>
  <calculations-card :title="$t('mainCalculationsHeat.title')" icon="thermometerOutline">
    <div>
      <div>
        {{ $t("mainCalculationsHeat.explanations.neededHeat", { heatTwh: this.getHeatTwh }) }}
      </div>
    </div>
  </calculations-card>
</template>

<script>
import CalculationsCard from "../components/MainCalculations/CalculationsCard.vue";
export default {
  components: {
    CalculationsCard,
  },
  computed: {
    getHeatTwh: function () {
      if (isNaN(this.$store.getters["calculations/getHeatTwh"])) {
        return "-";
      } else {
        return this.$store.getters["calculations/getHeatTwh"].toFixed(2);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
ion-card {
  top: 285px;
  height: 70px;
  * {
    font-size: 75%;
  }
}

@media screen and (min-width: 576px) {
  ion-card {
    top: 285px;
    height: 70px;
  }
}
@media screen and (min-width: 768px) {
  ion-card {
    top: 380px;
    height: 115px;
    * {
      font-size: 80%;
    }
  }
}
@media screen and (min-width: 992px) {
  ion-card {
    top: 520px;
    height: 150px;
    * {
      font-size: 90%;
    }
  }
}
@media screen and (min-width: 1200px) {
  ion-card {
    top: 620px;
    height: 180px;
    * {
      font-size: 100%;
    }
  }
}
</style>
