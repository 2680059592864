<template>
  <div class="calculation-label-container">
    <calculation-label :value="$t('mainCalculations.co2ElectricityLabel.value')"></calculation-label>
  </div>
</template>

<script>
import CalculationLabel from "./CalculationLabel.vue";
export default { components: { CalculationLabel } };
</script>

<style lang="scss" scoped>
.calculation-label-container {
  position: absolute;
}

@media screen and (min-width: 576px) {
  .calculation-label-container {
    top: 237px;
    left: 213px;
  }
}
@media screen and (min-width: 768px) {
  .calculation-label-container {
    top: 328px;
    left: 288px;
  }
}
@media screen and (min-width: 992px) {
  .calculation-label-container {
    top: 448px;
    left: 390px;
  }
}
@media screen and (min-width: 1200px) {
  .calculation-label-container {
    top: 537px;
    left: 466px;
  }
}
</style>
