<template>
  <ion-card id="electricity-sources-settings">
    <ion-accordion-group value="open">
      <ion-accordion value="open">
        <ion-item slot="header">
          <ion-card-header>
            <ion-card-title>
              <ion-icon :icon="flashOutline"></ion-icon>
              <ion-label>{{ $t("electricitySources.title") }}</ion-label>
            </ion-card-title>
          </ion-card-header>
        </ion-item>

        <ion-card-content slot="content">
          <calculation-warnings
            :filter="['electricityMiscellaneous', 'electricityPotentials', 'processElectricity']"
          ></calculation-warnings>
          <template v-if="this.getCalculationType() == 'offer'">
            <ion-list>
              <ion-item>
                <consider-gross-electricity-consumption-checkbox></consider-gross-electricity-consumption-checkbox>
                <ion-label>{{ $t("electricitySources.considerGrossElectricityConsumptionCheckbox.label") }}</ion-label>
              </ion-item>
            </ion-list>

            <usable-electricity-percentage-range></usable-electricity-percentage-range>

            <electricity-sources-checkboxes></electricity-sources-checkboxes>
          </template>
        </ion-card-content>
      </ion-accordion>
    </ion-accordion-group>
  </ion-card>
</template>

<script>
import { mapGetters } from "vuex";
import CalculationWarnings from "./CalculationWarnings.vue";
import ElectricitySourcesCheckboxes from "./ElectricitySources/ElectricitySourcesCheckboxes.vue";
import ConsiderGrossElectricityConsumptionCheckbox from "./ElectricitySources/ConsiderGrossElectricityConsumptionCheckbox.vue";
import UsableElectricityPercentageRange from "./ElectricitySources/UsableElectricityPercentageRange.vue";
import {
  IonAccordion,
  IonAccordionGroup,
  IonCard,
  IonCardTitle,
  IonCardHeader,
  IonCardContent,
  IonLabel,
  IonList,
  IonItem,
  IonIcon,
} from "@ionic/vue";
import { flashOutline } from "ionicons/icons";
export default {
  components: {
    IonAccordion,
    IonAccordionGroup,
    IonCard,
    IonCardTitle,
    IonCardHeader,
    IonCardContent,
    IonLabel,
    IonList,
    IonItem,
    IonIcon,
    CalculationWarnings,
    ElectricitySourcesCheckboxes,
    ConsiderGrossElectricityConsumptionCheckbox,
    UsableElectricityPercentageRange,
  },
  computed: {
    ...mapGetters("settings", ["getCalculationType"]),
  },
  setup() {
    return { flashOutline };
  },
};
</script>

<style lang="scss" scoped>
ion-card {
  height: calc(100% - 20px);
}
</style>
<style lang="scss" scoped></style>
