import axios from "axios";

export default {
  namespaced: true,
  state: {
    productRequirements: [],
  },
  getters: {
    getAll: (state) => () => {
      return state.productRequirements;
    },
    getById: (state) => (id) => {
      return state.productRequirements.find((productRequirement) => productRequirement.id === id);
    },
    getByProductId: (state) => (productId) => {
      return state.productRequirements.find((productRequirement) => productRequirement.attributes.product_id === productId);
    },
    getByProductIdAndYearId: (state) => (productId, yearId) => {
      return state.productRequirements.find((productRequirement) => {
        return productRequirement.attributes.product_id === productId && productRequirement.attributes.year.data.id === yearId
      });
    },
  },
  actions: {
    async fetchAll({ commit }) {
      try {
        const data = await axios.get(`${process.env.VUE_APP_BACKEND_BASE_URL}/product-requirements?populate=year`, {
          headers: {
            "Content-Type": "application/json",
            "Accept": "application/json",
            Authorization: `Bearer ${process.env.VUE_APP_BACKEND_API_TOKEN}`,
          },
        });
        commit("SET_PRODUCT_REQUIREMENTS", data.data.data);
      } catch (error) {
        console.log(error);
      }
    },
  },
  mutations: {
    SET_PRODUCT_REQUIREMENTS(state, productRequirements) {
      state.productRequirements = productRequirements;
    },
  },
};
