<template>
  <calculation-value
    :prefix="prefix"
    :value="value"
    :suffix="suffix"
    id="ome-3-5-electricity-value"
  ></calculation-value>
</template>

<script>
import CalculationValue from "./CalculationValue.vue";
export default {
  components: {
    CalculationValue,
  },
  props: {
    prefix: {
      type: String,
      required: false,
    },
    value: {
      type: String,
      required: true,
    },
    suffix: {
      type: String,
      required: false,
    },
  },
};
</script>

<style lang="scss" scoped>
#ome-3-5-electricity-value {
  top: 325px;
  left: 548px;
}

@media screen and (min-width: 576px) {
  #ome-3-5-electricity-value {
    top: 223px;
    left: 256px;
  }
}
@media screen and (min-width: 768px) {
  #ome-3-5-electricity-value {
    top: 304px;
    left: 348px;
  }
}
@media screen and (min-width: 992px) {
  #ome-3-5-electricity-value {
    top: 410px;
    left: 471px;
  }
}
@media screen and (min-width: 1200px) {
  #ome-3-5-electricity-value {
    top: 490px;
    left: 564px;
  }
}
</style>
