import { getField } from "vuex-map-fields";

export default {
  namespaced: true,
  state: {
    co2SourceCategoryId: null,
    co2SourceIds: [],
    calculationType: null,
    countryId: null,
    electricityTypeIds: [],
    considerGrossElectricityConsumption: null,
    productId: null,
    usableElectricityPercentage: 100,
    waterSource: null,
    yearId: null,
  },
  getters: {
    getField,
    electricityTypesIncludeId: (state) => (id) => {
      return state.electricityTypeIds.includes(id);
    },
    co2SourceIdsIncludeId: (state) => (id) => {
      return state.co2SourceIds.includes(id);
    },
    getState: (state) => () => {
      return state;
    },
    getCo2SourceCategoryId: (state) => () => {
      return state.co2SourceCategoryId;
    },
    getCalculationType: (state) => () => {
      return state.calculationType
    },
    getProductId: (state) => () => {
      return state.productId;
    },
    getConsiderGrossElectricityConsumption: (state) => () => {
      return state.considerGrossElectricityConsumption
    },
    getUsableElectricityPercentage: (state) => () => {
      return state.usableElectricityPercentage
    },
    getWaterSource: (state) => () => {
      return state.waterSource
    },
    getComplete: (state) => () => {
      let result = true
      Object.values(state).every(value => {
        if (value === null) {
          result = false;
          return
        }
      });
      return result;
    }
  },
  mutations: {
    updateField(state, { path, value }) {
      path.split(/[.[\]]+/).reduce((prev, key, index, array) => {
        if (array.length === index + 1) {
          // eslint-disable-next-line no-param-reassign
          prev[key] = value;
        }
        if (this.getters["settings/getComplete"]()) {
          this.dispatch("settings/updateCalculationsState");
        }
        return prev[key];
      }, state);
    },
    selectElectricityTypeId(state, payload) {
      if (payload.event) {
        state.electricityTypeIds = state.electricityTypeIds.filter((f) => f !== payload.id).concat([payload.id]);
      } else {
        state.electricityTypeIds = state.electricityTypeIds.filter(function (e) {
          return e !== payload.id;
        });
      }
      if (this.getters["settings/getComplete"]()) {
        this.dispatch("settings/updateCalculationsState");
      }
    },
    selectCo2SourceId(state, payload) {
      if (payload.event) {
        state.co2SourceIds = state.co2SourceIds.filter((f) => f !== payload.id).concat([payload.id]);
      } else {
        state.co2SourceIds = state.co2SourceIds.filter(function (e) {
          return e !== payload.id;
        });
      }
      if (this.getters["settings/getComplete"]()) {
        this.dispatch("settings/updateCalculationsState");
      }
    },
    setCountryId(state, payload) {
      state.countryId = payload.id;
    },
    setYearId(state, payload) {
      state.yearId = payload.id;
    },
    setProductId(state, payload) {
      state.productId = payload.id;
    },
    setCalculationTypeId(state, payload) {
      state.calculationType = payload.id;
    },
    setCalculationTypeIdentifier(state, payload) {
      state.calculationType = payload.identifier;
    },
    setConsiderGrossElectricityConsumption(state, payload) {
      state.considerGrossElectricityConsumption = payload;
    },
    setWaterType(state, payload) {
      state.waterType = payload;
    },
    setCo2SourceCategoryId(state, payload) {
      state.co2SourceCategoryId = payload.id;
    },
    setElectricityTypeIds(state, payload) {
      state.electricityTypeIds = payload;
    },
    setWaterSourceIdentifier(state, payload) {
      state.waterSource = payload.identifier;
    },
  },
  actions: {
    updateCalculationsState({ commit }) {
      commit("calculations/UPDATE_INPUT", { ...this.state.settings }, { root: true });
      commit("calculationsMany/UPDATE_INPUT", { ...this.state.settings }, { root: true });
    },
  },
};
