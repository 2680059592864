export default {
  methods: {
    formatNumber(number, type = "exponential", precision = "4", unit = null) {
      if (type == "exponential") {
        return [((number === null || isNaN(number)) ? "-" : parseFloat(number).toExponential(precision)), unit].filter((n) => n).join(" ");
      } else {
        return [((number === null || isNaN(number)) ? "-" : parseFloat(number).toFixed(precision)), unit].filter((n) => n).join(" ");
      }
    }
  }
}
