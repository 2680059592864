import axios from "axios";
import debounce from 'lodash.debounce';
import { getLocale } from '../../i18n';

export default {
  namespaced: true,
  state: {
    input: {},
    output: {},
  },
  getters: {
    getElectricityPotentialTwh: (state) => (yearLabel) => {
      return parseFloat(state.output[yearLabel]?.data?.electricityPotentialTwh);
    },
    getElectricityPotentials: (state) => (yearLabel) => {
      if (state.output[yearLabel]?.data?.electricityPotentials === undefined) {
        return []
      }
      let result = state.output[yearLabel]?.data?.electricityPotentials.map(ep => ({ ...ep, valueTwh: parseFloat(ep.valueTwh) }))
      return result
    },
    getElectricityMiscellaneousTwh: (state) => (yearLabel) => {
      return parseFloat(state.output[yearLabel]?.data?.electricityMiscellaneousTwh);
    },
    getElectricityOwnUseTwh: (state) => (yearLabel) => {
      return parseFloat(state.output[yearLabel]?.data?.electricityOwnUseTwh);
    },
    getProcessElectricityTwh: (state) => (yearLabel) => {
      return parseFloat(state.output[yearLabel]?.data?.processElectricityTwh);
    },
    getWaterElectricityTwh: (state) => (yearLabel) => {
      return parseFloat(state.output[yearLabel]?.data?.waterElectricityTwh);
    },
    getCo2ElectricityTwh: (state) => (yearLabel) => {
      return parseFloat(state.output[yearLabel]?.data?.co2ElectricityTwh);
    },
    getElectrolysisElectricityTwh: (state) => (yearLabel) => {
      return parseFloat(state.output[yearLabel]?.data?.electrolysisElectricityTwh);
    },
    getProductElectricityTwh: (state) => (yearLabel) => {
      return parseFloat(state.output[yearLabel]?.data?.productElectricityTwh);
    },
    getProductHydrogenT: (state) => (yearLabel) => {
      return parseFloat(state.output[yearLabel]?.data?.productHydrogenT);
    },
    getNetProductT: (state) => (yearLabel) => {
      return parseFloat(state.output[yearLabel]?.data?.netProductT);
    },
    getGrossProductT: (state) => (yearLabel) => {
      return parseFloat(state.output[yearLabel]?.data?.grossProductT);
    },
    getProductHeatTwh: (state) => (yearLabel) => {
      return parseFloat(state.output[yearLabel]?.data?.productHeatTwh);
    },
    getProductCo2T: (state) => (yearLabel) => {
      return parseFloat(state.output[yearLabel]?.data?.productCo2T);
    },
    getCo2HeatTwh: (state) => (yearLabel) => {
      return parseFloat(state.output[yearLabel]?.data?.co2HeatTwh);
    },
    getWaterHeatTwh: (state) => (yearLabel) => {
      return parseFloat(state.output[yearLabel]?.data?.waterHeatTwh);
    },
    getAvailableWaterT: (state) => (yearLabel) => {
      return parseFloat(state.output[yearLabel]?.data?.availableWaterT);
    },
    getNeededWaterT: (state) => (yearLabel) => {
      return parseFloat(state.output[yearLabel]?.data?.neededWaterT);
    },
    getWaterFractionOfFreshWaterPercent: (state) => (yearLabel) => {
      return parseFloat(state.output[yearLabel]?.data?.waterFractionOfFreshWaterPercent);
    },
    getHeatTwh: (state) => (yearLabel) => {
      return parseFloat(state.output[yearLabel]?.data?.heatTwh);
    },
    getYear: (state) => (yearLabel) => {
      return state.output[yearLabel]?.data?.year;
    },
    getYears: (state) => () => {
      return Object.values(state.output).map((dataset) => dataset.data.year);
    },
    getWarnings(state) {
      return state.output.warnings || [];
    },
    getMeta(state) {
      return state.output.meta || {};
    },
    getData(state) {
      return state.output.data || {};
    },
  },
  mutations: {
    UPDATE_INPUT(state, payload) {
      state.input = payload;
      this.dispatch("calculationsMany/debounceFetchCalculations");
    },
    UPDATE_OUTPUT(state, payload) {
      state.output = payload;
    },
    SET_OUTPUT(state, output) {
      state.output = output;
    },
  },
  actions: {
    debounceFetchCalculations: debounce(({ dispatch }) => {
      dispatch("fetchCalculations");
    }, 500),
    async fetchCalculations({ commit }) {
      commit("errors/SET_ERROR", null, { root: true });
      try {
        const params = { data: this.state.calculations.input }
        const data = await axios.post(`${process.env.VUE_APP_BACKEND_BASE_URL}/calculate?locale=${getLocale()}`, params, {
          headers: {
            "Content-Type": "application/json",
            "Accept": "application/json",
            Authorization: `Bearer ${process.env.VUE_APP_BACKEND_API_TOKEN}`,
          },
        });
        commit("SET_OUTPUT", data.data);
      } catch (error) {
        commit("errors/SET_ERROR", error, { root: true });
        commit("errors/SET_OPEN", true, { root: true });
      }
    },
  },
};
