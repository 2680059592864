<template>
  <ion-radio-group v-model="waterSource">
    <ion-list>
      <ion-item v-for="waterSource in waterSources" :key="waterSource.id">
        <ion-label>{{ waterSource.attributes[`label_${$i18n.locale}`] }}</ion-label>
        <ion-radio slot="start" :value="waterSource.attributes.identifier"></ion-radio>
      </ion-item>
    </ion-list>
  </ion-radio-group>
</template>

<script>
import { mapState, mapGetters } from "vuex";
import { mapFields } from "vuex-map-fields";
import { IonRadioGroup, IonRadio, IonList, IonItem, IonLabel } from "@ionic/vue";
export default {
  components: {
    IonRadioGroup,
    IonRadio,
    IonList,
    IonItem,
    IonLabel,
  },
  computed: {
    ...mapState("waterSources", {
      waterSources: (state) => state.waterSources,
    }),
    ...mapGetters("waterSources", ["getDefaultWaterSource"]),
    ...mapFields("settings", ["waterSource"]),
  },
  mounted: async function () {
    await this.$store.dispatch("waterSources/fetchAll");
    await this.$store.commit("settings/setWaterSourceIdentifier", {
      identifier: this.getDefaultWaterSource().attributes.identifier,
    });
  },
};
</script>

<style lang="scss" scoped></style>
