<template>
  <calculation-value :prefix="prefix" :value="value" :suffix="suffix" id="ome-3-5-heat-value"></calculation-value>
</template>

<script>
import CalculationValue from "./CalculationValue.vue";
export default {
  components: {
    CalculationValue,
  },
  props: {
    prefix: {
      type: String,
      required: false,
    },
    value: {
      type: String,
      required: true,
    },
    suffix: {
      type: String,
      required: false,
    },
  },
};
</script>

<style lang="scss" scoped>
#ome-3-5-heat-value {
  top: 444px;
  left: 678px;
}

@media screen and (min-width: 576px) {
  #ome-3-5-heat-value {
    top: 321px;
    left: 315px;
  }
}
@media screen and (min-width: 768px) {
  #ome-3-5-heat-value {
    top: 438px;
    left: 428px;
  }
}
@media screen and (min-width: 992px) {
  #ome-3-5-heat-value {
    top: 592px;
    left: 580px;
  }
}
@media screen and (min-width: 1200px) {
  #ome-3-5-heat-value {
    top: 708px;
    left: 694px;
  }
}
</style>
