<template>
  <ion-card>
    <ion-accordion-group value="open">
      <ion-accordion value="open">
        <ion-item slot="header">
          <ion-card-header>
            <ion-card-title>
              <ion-icon :icon="listOutline"></ion-icon>
              <ion-label>{{ $t("mainResults.title") }}</ion-label>
            </ion-card-title>
          </ion-card-header>
          <div class="download-button-container">
            <download-button></download-button>
          </div>
        </ion-item>

        <ion-card-content slot="content">
          <template v-if="Object.keys(this.getWarnings()).length > 0">
            <p>{{ $t("mainResults.warnings.missingData") }}</p>
            <calculation-warnings></calculation-warnings>
            <p>{{ $t("mainResults.warnings.invalidSettings") }}</p>
          </template>
          <template v-else>
            <result-info></result-info>
          </template>
          <template v-if="this.getCalculationType() == 'offer'">
            <offer-results-table></offer-results-table>
          </template>
          <template v-if="this.getCalculationType() == 'demand'">
            <demand-results-table></demand-results-table
          ></template>

          <div v-html="$t('mainResults.explanations.about')"></div>
          <div v-html="$t('mainResults.explanations.technicalParameters')"></div>
        </ion-card-content>
      </ion-accordion>
    </ion-accordion-group>
  </ion-card>
</template>

<script>
import { mapGetters } from "vuex";
import {
  IonCard,
  IonCardHeader,
  IonCardTitle,
  IonCardContent,
  IonIcon,
  IonAccordion,
  IonAccordionGroup,
  IonLabel,
  IonItem,
} from "@ionic/vue";
import DownloadButton from "./MainResults/DownloadButton.vue";
import OfferResultsTable from "./MainResults/OfferResultsTable.vue";
import DemandResultsTable from "./MainResults/DemandResultsTable.vue";
import ResultInfo from "./MainResults/ResultInfo.vue";
import CalculationWarnings from "./CalculationWarnings.vue";
import { listOutline } from "ionicons/icons";
export default {
  computed: {
    ...mapGetters("calculations", ["getWarnings"]),
    ...mapGetters("settings", ["getCalculationType"]),
  },
  components: {
    CalculationWarnings,
    DownloadButton,
    IonCard,
    IonCardHeader,
    IonCardTitle,
    IonCardContent,
    IonIcon,
    IonAccordion,
    IonAccordionGroup,
    IonLabel,
    IonItem,
    OfferResultsTable,
    DemandResultsTable,
    ResultInfo,
  },
  setup() {
    return { listOutline };
  },
};
</script>

<style lang="scss" scoped>
p {
  margin-bottom: 1rem;
}
</style>
