<template>
  <div class="process-label-container">
    <process-label :value="$t('mainCalculations.chemicalTransformationLabel.value')"></process-label>
  </div>
</template>

<script>
import ProcessLabel from "./ProcessLabel.vue";
export default { components: { ProcessLabel } };
</script>

<style lang="scss" scoped>
.process-label-container {
  position: absolute;
}

@media screen and (min-width: 576px) {
  .process-label-container {
    top: 217px;
    left: 367px;
  }
}
@media screen and (min-width: 768px) {
  .process-label-container {
    top: 300px;
    left: 511px;
  }
}
@media screen and (min-width: 992px) {
  .process-label-container {
    top: 410px;
    left: 691px;
  }
}
@media screen and (min-width: 1200px) {
  .process-label-container {
    top: 493px;
    left: 819px;
  }
}
</style>
