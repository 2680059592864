<template>
  <calculation-value :prefix="prefix" :value="value" :suffix="suffix" id="own-use-value"></calculation-value>
</template>

<script>
import CalculationValue from "./CalculationValue.vue";
export default {
  components: {
    CalculationValue,
  },
  props: {
    prefix: {
      type: String,
      required: false,
    },
    value: {
      type: String,
      required: true,
    },
    suffix: {
      type: String,
      required: false,
    },
  },
};
</script>

<style lang="scss" scoped>
@media screen and (min-width: 576px) {
  #own-use-value {
    top: 163px;
    left: 256px;
  }
}
@media screen and (min-width: 768px) {
  #own-use-value {
    top: 223px;
    left: 348px;
  }
}
@media screen and (min-width: 992px) {
  #own-use-value {
    top: 302px;
    left: 471px;
  }
}
@media screen and (min-width: 1200px) {
  #own-use-value {
    top: 361px;
    left: 564px;
  }
}
</style>
