<template>
  <ion-card>
    <ion-accordion-group value="open">
      <ion-accordion value="open">
        <ion-item slot="header">
          <ion-card-header>
            <ion-card-title>
              <ion-icon :icon="informationOutline"></ion-icon>
              <ion-label>Debugging Info</ion-label>
            </ion-card-title>
          </ion-card-header>
        </ion-item>

        <ion-card-content slot="content">
          Settings:
          <pre>{{ this.$store.state.settings }}</pre>
        </ion-card-content>
        <ion-card-content slot="content">
          Calculations Input:
          <pre>{{ this.$store.state.calculations.input }}</pre>
        </ion-card-content>
        <ion-card-content slot="content">
          Calculations Output:
          <pre>{{ this.$store.state.calculations.output }}</pre>
        </ion-card-content>
      </ion-accordion>
    </ion-accordion-group>
  </ion-card>
</template>

<script>
import {
  IonCard,
  IonCardHeader,
  IonCardTitle,
  IonCardContent,
  IonIcon,
  IonAccordion,
  IonAccordionGroup,
  IonLabel,
  IonItem,
} from "@ionic/vue";
import { informationOutline } from "ionicons/icons";
export default {
  components: {
    IonCard,
    IonCardHeader,
    IonCardTitle,
    IonCardContent,
    IonIcon,
    IonAccordion,
    IonAccordionGroup,
    IonLabel,
    IonItem,
  },
  setup() {
    return { informationOutline };
  },
};
</script>

<style lang="scss" scoped></style>
