<template>
  <calculations-card :title="$t('mainCalculationsCo2.title')" icon="cloudOutline">
    <div>
      <template v-if="productIdentifier == 'hydrogen'">
        <div>
          {{ $t("mainCalculationsCo2.explanations.hydrogen.noCo2Needed") }}
        </div>
      </template>
      <template v-else>
        <template v-if="calculationType() == 'offer'">
          <div>
            <template v-if="isNaN(co2Mt)">
              {{
                $t("mainCalculationsCo2.explanations.offer.missingData", {
                  co2SourceCategoryLabel: co2SourceCategoryLabel,
                })
              }}
            </template>
            <template v-else>
              {{
                $t("mainCalculationsCo2.explanations.offer.neededCo2", {
                  co2Mt: co2Mt,
                  co2SourceCategoryLabel: co2SourceCategoryLabel,
                })
              }}
            </template>
          </div>
        </template>
        <template v-if="calculationType() == 'demand'">
          <div>
            {{
              $t("mainCalculationsCo2.explanations.demand.neededCo2", {
                co2Mt: co2Mt,
                co2SourceCategoryLabel: co2SourceCategoryLabel,
              })
            }}
          </div>
        </template>
      </template>
    </div>
  </calculations-card>
</template>

<script>
import CalculationsCard from "../components/MainCalculations/CalculationsCard.vue";
export default {
  components: {
    CalculationsCard,
  },
  props: ["co2Mt", "productIdentifier", "calculationType", "co2SourceCategoryLabel"],
};
</script>

<style lang="scss" scoped>
ion-card {
  top: 375px;
  height: 70px;
  * {
    font-size: 75%;
  }
}

@media screen and (min-width: 576px) {
  ion-card {
    top: 375px;
    height: 70px;
  }
}
@media screen and (min-width: 768px) {
  ion-card {
    top: 505px;
    height: 110px;
    * {
      font-size: 80%;
    }
  }
}
@media screen and (min-width: 992px) {
  ion-card {
    top: 690px;
    height: 150px;
    * {
      font-size: 90%;
    }
  }
}
@media screen and (min-width: 1200px) {
  ion-card {
    top: 820px;
    height: 190px;
    * {
      font-size: 100%;
    }
  }
}
</style>
