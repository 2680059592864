<template>
  <ion-img :src="image" />
</template>

<script>
import { computed } from "vue";
import { IonImg } from "@ionic/vue";
export default {
  components: {
    IonImg,
  },
  setup() {
    const image = computed(() => require("../../assets/images/chemical-transformation-icon.svg"));

    return {
      image,
    };
  },
};
</script>

<style lang="scss" scoped>
ion-img {
  position: absolute;
}
@media screen and (min-width: 576px) {
  ion-img {
    top: 312px;
    left: 390px;
    width: 36px;
    height: 36px;
  }
}
@media screen and (min-width: 768px) {
  ion-img {
    top: 424px;
    left: 531px;
    width: 48px;
    height: 48px;
  }
}
@media screen and (min-width: 992px) {
  ion-img {
    top: 573px;
    left: 720px;
    width: 64px;
    height: 64px;
  }
}
@media screen and (min-width: 1200px) {
  ion-img {
    top: 683px;
    left: 858px;
    width: 80px;
    height: 80px;
  }
}
</style>
