<template>
  <doughnut-chart :chartData="chartData" :options="chartOptions" />
</template>

<script>
import { defineComponent } from "vue";
import { mapGetters } from "vuex";
import { DoughnutChart } from "vue-chart-3";
import { Chart, registerables } from "chart.js";
import formatNumberMixin from "../../mixins/formatNumberMixin.js";

Chart.register(...registerables);

export default defineComponent({
  mixins: [formatNumberMixin],
  components: { DoughnutChart },
  methods: {
    capitalize: (s) => {
      if (typeof s !== "string") return "";
      return s.charAt(0).toUpperCase() + s.slice(1);
    },
  },
  computed: {
    ...mapGetters("calculations", ["getElectricityPotentials"]),
    chartOptions() {
      return {
        responsive: true,
        plugins: {
          tooltip: {
            callbacks: {
              label: (context) => {
                return `${context.label}: ${this.formatNumber(context.parsed, "fixed", 2, "TWh")}`;
              },
            },
          },
        },
      };
    },
    chartData() {
      return {
        labels: this.getElectricityPotentials.map((ep) => ep[`label${this.capitalize(this.$i18n.locale)}`]),
        datasets: [
          {
            data: this.getElectricityPotentials.map((ep) => ep.valueTwh),
            backgroundColor: ["#77CEFF", "#0079AF", "#123E6B", "#97B0C4", "#A5C8ED"],
          },
        ],
      };
    },
  },
});
</script>
