export default {
  namespaced: true,
  state: {
    error: null,
    open: false
  },
  getters: {
    getError: (state) => () => {
      return state.error;
    },
    getOpen: (state) => () => {
      return state.open;
    },
  },
  mutations: {
    SET_ERROR(state, error) {
      state.error = error;
    },
    SET_OPEN(state, open) {
      state.open = open;
    },
    RESET(state) {
      state.open = false;
      state.error = null;
    }
  },
};
