<template>
  <a href="https://namosyn.de/" target="_blank">
    <ion-img :src="image" />
  </a>
</template>

<script>
import { computed } from "vue";
import { IonImg } from "@ionic/vue";
export default {
  components: {
    IonImg,
  },
  setup() {
    const image = computed(() => require("../assets/images/namosyn-logo.svg"));

    return {
      image,
    };
  },
};
</script>

<style lang="scss" scoped></style>
