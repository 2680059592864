<template>
  <div class="process-label-container">
    <process-label :value="$t('mainCalculations.electrolysisLabel.value')"></process-label>
  </div>
</template>

<script>
import ProcessLabel from "./ProcessLabel.vue";
export default { components: { ProcessLabel } };
</script>

<style lang="scss" scoped>
.process-label-container {
  position: absolute;
}

@media screen and (min-width: 576px) {
  .process-label-container {
    top: 340px;
    left: 385px;
  }
}
@media screen and (min-width: 768px) {
  .process-label-container {
    top: 464px;
    left: 531px;
  }
}
@media screen and (min-width: 992px) {
  .process-label-container {
    top: 632px;
    left: 720px;
  }
}
@media screen and (min-width: 1200px) {
  .process-label-container {
    top: 760px;
    left: 859px;
  }
}
</style>
