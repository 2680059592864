<template>
  <ion-card>
    <ion-card-header v-if="hideHeader === false">
      <ion-card-title v-if="title || icon">
        <template v-if="icon">
          <ion-icon v-if="icon == 'waterOutline'" :icon="waterOutline"></ion-icon>
          <ion-icon v-if="icon == 'flashOutline'" :icon="flashOutline"></ion-icon>
          <ion-icon v-if="icon == 'cloudOutline'" :icon="cloudOutline"></ion-icon>
          <ion-icon v-if="icon == 'thermometerOutline'" :icon="thermometerOutline"></ion-icon>
        </template>
        <span v-html="title"> </span>
      </ion-card-title>
    </ion-card-header>

    <ion-card-content :style="contentStyle">
      <slot />
    </ion-card-content>
  </ion-card>
</template>

<script>
import { IonCard, IonCardHeader, IonCardTitle, IonCardContent, IonIcon } from "@ionic/vue";
import { waterOutline, flashOutline, cloudOutline, thermometerOutline } from "ionicons/icons";
export default {
  props: {
    icon: {
      type: String,
    },
    title: {
      type: String,
    },
    contentStyle: {
      type: String,
    },
    hideHeader: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  components: {
    IonCard,
    IonCardHeader,
    IonCardTitle,
    IonCardContent,
    IonIcon,
  },
  setup() {
    return { waterOutline, flashOutline, cloudOutline, thermometerOutline };
  },
};
</script>

<style lang="scss" scoped>
ion-card {
  position: absolute;
  width: 155px;
}

ion-card-content {
  display: flex;
  align-items: center;
  font-size: 0.75rem;
}

ion-card-title {
  font-size: 0.75rem;
}

ion-icon {
  display: none;
}

@media screen and (min-width: 576px) {
  ion-card {
    width: 155px;
  }

  ion-card-title {
    font-size: 0.75rem;
  }

  ion-card-content {
    font-size: 0.75rem;
  }

  ion-card-header {
    padding: 10px;
  }

  ion-card-content {
    padding: 10px;
    padding-top: 0;
  }
}
@media screen and (min-width: 768px) {
  ion-card {
    width: 220px;
  }

  ion-card-title {
    font-size: 0.75rem;
  }

  ion-card-content {
    font-size: 1.125rem;
  }

  ion-card-header {
    padding: 10px;
  }

  ion-card-content {
    padding: 10px;
    padding-top: 0;
  }

  ion-icon {
    display: inline;
  }
}
@media screen and (min-width: 992px) {
  ion-card {
    width: 310px;
  }

  ion-card-header {
    padding: 20px;
  }

  ion-card-content {
    padding: 20px;
    padding-top: 0;
  }

  ion-card-title {
    font-size: 1.25rem;
  }
}
@media screen and (min-width: 1200px) {
  ion-card {
    width: 375px;
  }
}
</style>
