<template>
  <div class="main-hero" v-bind:style="{ backgroundImage: 'url(' + image + ')' }">
    <ion-grid>
      <ion-row>
        <ion-col offset="6" size="5" offset-md="6" size-md="6" class="main-hero-text text-white">
          <template v-if="$i18n.locale == 'de'">
            <h1>Power-to-X Potenzialanalyse</h1>
            <p>Interaktive Berechnung von PtX-Potenzialen</p>
            <ul>
              <li>für 2020, 2030, 2040 und 2050</li>
              <li>über 30 Länder</li>
              <li>abgestimmt auf Angebot und Nachfrage</li>
            </ul>
          </template>
          <template v-else>
            <h1>Power-to-X Potential Analysis</h1>
            <p>Interactive calculation of PtX potentials</p>
            <ul>
              <li>for 2020, 2030, 2040 and 2050</li>
              <li>Over 30 countries</li>
              <li>For supply and demand</li>
            </ul>
          </template>
        </ion-col>
      </ion-row>
    </ion-grid>
  </div>
</template>

<script>
import { computed } from "vue";
import { IonGrid, IonCol, IonRow } from "@ionic/vue";
export default {
  components: { IonGrid, IonCol, IonRow },
  setup() {
    const image = computed(() => require("../assets/images/main-hero.png"));

    return {
      image,
    };
  },
};
</script>

<style lang="scss" scoped>
.main-hero {
  background-size: cover;
  aspect-ratio: 2 / 1;
  display: flex;
  justify-content: center;
  width: 100%;

  h1 {
    font-size: 100%;
  }

  p,
  ul {
    font-size: 85%;
  }
}

.text-white {
  color: white;
}

ul {
  position: relative;
  list-style: none;
}

li::before {
  content: "\2022";
  color: var(--ion-color-primary);
  display: inline-block;
  width: 2rem;
  margin-left: -2.5rem;
  font-size: 100%;
}

@media screen and (max-width: 575.99px) {
  h1 {
    display: none;
  }
  li::before {
    width: 2rem;
    margin-left: -2rem;
  }
}

@media screen and (min-width: 576px) {
  .main-hero {
    align-items: center;
    .main-hero-text {
      padding-left: 2rem;
    }
    h1 {
      font-size: 150%;
    }

    p,
    ul {
      font-size: 100%;
    }
    li::before {
      width: 2rem;
      margin-left: -2rem;
    }
  }
}

@media screen and (min-width: 768px) {
  .main-hero {
    align-items: center;
    .main-hero-text {
      padding-left: 2rem;
    }
    h1 {
      font-size: 200%;
    }

    p,
    ul {
      font-size: 130%;
    }
    li::before {
      width: 2rem;
      margin-left: -2rem;
    }
  }
}

@media screen and (min-width: 992px) {
  .main-hero {
    align-items: center;
    .main-hero-text {
      padding-left: 2rem;
    }
    h1 {
      font-size: 250%;
    }

    p,
    ul {
      font-size: 170%;
    }
    li::before {
      width: 2rem;
      margin-left: -2rem;
    }
  }
}

@media screen and (min-width: 1200px) {
  .main-hero {
    .main-hero-text {
      padding-left: 4rem;
    }
    h1 {
      font-size: 400%;
    }

    p,
    ul {
      font-size: 200%;
    }
  }
}

@media screen and (min-width: 1500px) {
  .main-hero {
    .main-hero-text {
      padding-left: 4rem;
    }
    h1 {
      font-size: 600%;
    }

    p,
    ul {
      font-size: 250%;
    }
  }
}
</style>
