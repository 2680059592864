<template>
  <calculation-value :prefix="prefix" :value="value" :suffix="suffix" id="hydrogen-value"></calculation-value>
</template>

<script>
import CalculationValue from "./CalculationValue.vue";
export default {
  components: {
    CalculationValue,
  },
  props: {
    prefix: {
      type: String,
      required: false,
    },
    value: {
      type: String,
      required: true,
    },
    suffix: {
      type: String,
      required: false,
    },
  },
};
</script>

<style lang="scss" scoped>
#hydrogen-value {
  top: 247px;
  left: 924px;
}

@media screen and (min-width: 576px) {
  #hydrogen-value {
    top: 261px;
    left: 384px;
  }
}
@media screen and (min-width: 768px) {
  #hydrogen-value {
    top: 357px;
    left: 522px;
  }
}
@media screen and (min-width: 992px) {
  #hydrogen-value {
    top: 483px;
    left: 708px;
  }
}
@media screen and (min-width: 1200px) {
  #hydrogen-value {
    top: 578px;
    left: 846px;
  }
}
</style>
