import axios from "axios";

export default {
  namespaced: true,
  state: {
    co2sources: [],
  },
  getters: {
    getAll: (state) => () => {
      return state.co2sources;
    },
    getById: (state) => (id) => {
      return state.co2sources.find((co2source) => co2source.id === id);
    },
    getByName: (state) => (name) => {
      return state.co2sources.find((co2source) => co2source.attributes.label_de === name);
    },
    getByCo2SourceCategoryId: (state) => (id) => {
      const output = state.co2sources.filter((co2source) => co2source.attributes.co2_source_category.data.id == id);
      return output;
    },
  },
  actions: {
    async fetchAll({ commit }) {
      try {
        const data = await axios.get(`${process.env.VUE_APP_BACKEND_BASE_URL}/co-2-sources?populate[]=co2_source_category`, {
          headers: {
            "Content-Type": "application/json",
            "Accept": "application/json",
            Authorization: `Bearer ${process.env.VUE_APP_BACKEND_API_TOKEN}`,
          },
        });
        commit("SET_CO2_SOURCES", data.data.data);
      } catch (error) {
        console.log(error);
      }
    },
  },
  mutations: {
    SET_CO2_SOURCES(state, co2sources) {
      state.co2sources = co2sources;
    },
  },
};
