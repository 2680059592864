<template>
  <div class="calculation-value-container">
    <div
      class="calculation-value border"
      v-html="content"
      :class="[isNaN(value) ? 'isNaN' : null, iPad(value) ? 'ipad' : null]"
    ></div>
  </div>
</template>

<script>
import iPadMixin from "../../mixins/iPadMixin.js";
export default {
  props: {
    prefix: {
      type: String,
      required: false,
    },
    value: {
      type: String,
      required: true,
    },
    suffix: {
      type: String,
      required: false,
    },
  },
  computed: {
    content() {
      return [this.prefix, isNaN(this.value) ? "-" : this.value, this.suffix].filter((n) => n).join("");
    },
  },
  mixins: [iPadMixin],
};
</script>

<style lang="scss" scoped>
.calculation-value-container {
  position: absolute;
}

.calculation-value {
  background-color: var(--ion-color-primary-contrast);
  color: var(--ion-color-primary);
  border-radius: 3px;
  text-align: center;

  &.isNaN {
    border-color: var(--ion-color-danger);
    color: var(--ion-color-danger);
  }
}

.calculation-value {
  width: 104px;
  height: 33px;
}

@media screen and (min-width: 576px) {
  .calculation-value {
    width: 48px;
    height: 16px;
    line-height: 14px;
    font-size: 8px;
  }
}
@media screen and (min-width: 768px) {
  .calculation-value {
    width: 65px;
    height: 20px;
    line-height: 20px;
    font-size: 12px;

    &.ipad {
      line-height: 18px;
      font-size: 10px;
    }
  }
}
@media screen and (min-width: 992px) {
  .calculation-value {
    width: 87px;
    height: 27px;
    line-height: 27px;
    font-size: inherit;

    &.ipad {
      line-height: 27px;
      font-size: 12px;
    }
  }
}
@media screen and (min-width: 1200px) {
  .calculation-value {
    width: 104px;
    height: 33px;
    line-height: 33px;
    font-size: inherit;
    &.ipad {
      line-height: inherit;
    }
  }
}
</style>
