<template>
  <ion-radio-group v-model="calculationType">
    <ion-label position="floating">{{ $t("generalSettings.calculationTypeSelect.label") }}</ion-label>
    <ion-grid>
      <ion-row>
        <ion-col v-for="calculationType in calculationTypes" :key="calculationType.id">
          <ion-item>
            <ion-label>{{ calculationType.attributes[`label_${$i18n.locale}`] }}</ion-label>
            <ion-radio slot="start" :value="calculationType.attributes.identifier"></ion-radio>
          </ion-item>
        </ion-col>
      </ion-row>
    </ion-grid>
  </ion-radio-group>
</template>

<script>
import { mapState, mapGetters } from "vuex";
import { mapFields } from "vuex-map-fields";
import { IonRadioGroup, IonRadio, IonLabel, IonItem, IonCol, IonRow, IonGrid } from "@ionic/vue";
export default {
  components: {
    IonRadioGroup,
    IonRadio,
    IonLabel,
    IonItem,
    IonCol,
    IonRow,
    IonGrid,
  },
  computed: {
    ...mapState("calculationTypes", {
      calculationTypes: (state) => state.calculationTypes,
    }),
    ...mapGetters("calculationTypes", ["getDefaultCalculationType"]),
    ...mapFields("settings", ["calculationType"]),
  },
  mounted: async function () {
    await this.$store.dispatch("calculationTypes/fetchAll");
    await this.$store.commit("settings/setCalculationTypeIdentifier", {
      identifier: this.getDefaultCalculationType().attributes.identifier,
    });
  },
};
</script>

<style lang="scss" scoped></style>
