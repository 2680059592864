<template>
  <div class="calculation-label-container">
    <calculation-label
      v-if="this.product"
      :value="this.product.attributes[`label_${$i18n.locale}`]"
    ></calculation-label>
  </div>
</template>

<script>
import CalculationLabel from "./CalculationLabel.vue";
export default {
  components: { CalculationLabel },
  computed: {
    product() {
      return this.$store.getters["products/getById"](this.$store.state.settings.productId);
    },
  },
};
</script>

<style lang="scss" scoped>
.calculation-label-container {
  position: absolute;
}

@media screen and (min-width: 576px) {
  .calculation-label-container {
    top: 332px;
    left: 446px;
    width: 50px;
    text-align: center;
  }
}
@media screen and (min-width: 768px) {
  .calculation-label-container {
    top: 457px;
    left: 605px;
    width: 70px;
    text-align: center;
  }
}
@media screen and (min-width: 992px) {
  .calculation-label-container {
    top: 620px;
    left: 815px;
    width: 100px;
    text-align: center;
  }
}
@media screen and (min-width: 1200px) {
  .calculation-label-container {
    top: 740px;
    left: 975px;
    width: 120px;
    text-align: center;
  }
}
</style>
