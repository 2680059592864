<template>
  <ion-button @click.stop="download()" size="small">
    <ion-icon slot="start" :icon="cloudDownloadOutline"></ion-icon>
    <span class="label">xlsx</span>
  </ion-button>
</template>

<script>
import axios from "axios";
import { mapGetters } from "vuex";
import { IonButton, IonIcon } from "@ionic/vue";
import { cloudDownloadOutline } from "ionicons/icons";

export default {
  components: { IonButton, IonIcon },
  computed: {
    ...mapGetters("calculations", ["getInput"]),
  },
  setup() {
    return { cloudDownloadOutline };
  },
  methods: {
    download() {
      const params = { data: this.getInput };
      axios
        .post(`${process.env.VUE_APP_BACKEND_BASE_URL}/calculate/${this.getInput.yearId}.xlsx`, params, {
          headers: {
            "Content-Type": "application/json",
            Accept: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
            Authorization: `Bearer ${process.env.VUE_APP_BACKEND_API_TOKEN}`,
          },
          responseType: "blob",
        })
        .then((response) => {
          const blob = new Blob([response.data], {
            type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
          });
          const link = document.createElement("a");
          link.href = URL.createObjectURL(blob);

          const headerLine = response.headers["content-disposition"];
          const startFileNameIndex = headerLine.indexOf('"') + 1;
          const endFileNameIndex = headerLine.lastIndexOf('"');
          const filename = headerLine.substring(startFileNameIndex, endFileNameIndex);
          link.download = filename;
          link.click();
          URL.revokeObjectURL(link.href);
        })
        .catch(console.error);
    },
  },
};
</script>

<style lang="scss" scoped>
.label {
  display: none;
}

ion-icon {
  margin-left: 0rem;
  margin-right: 0rem;
}

@media screen and (min-width: 768px) {
  .label {
    display: inline-block;
  }
  ion-icon {
    margin-right: 0.5rem;
  }
}
</style>
