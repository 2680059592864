<template>
  <div class="calculation-label-container">
    <calculation-label :value="$t('mainCalculations.co2HeatLabel.value')"></calculation-label>
  </div>
</template>

<script>
import CalculationLabel from "./CalculationLabel.vue";
export default { components: { CalculationLabel } };
</script>

<style lang="scss" scoped>
.calculation-label-container {
  position: absolute;
}

@media screen and (min-width: 576px) {
  .calculation-label-container {
    top: 336px;
    left: 242px;
  }
}
@media screen and (min-width: 768px) {
  .calculation-label-container {
    top: 462px;
    left: 328px;
  }
}
@media screen and (min-width: 992px) {
  .calculation-label-container {
    top: 629px;
    left: 445px;
  }
}
@media screen and (min-width: 1200px) {
  .calculation-label-container {
    top: 753px;
    left: 533px;
  }
}
</style>
