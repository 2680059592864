<template>
  <a href="https://www.dechema.de/" target="_blank">
    <ion-img :src="image" />
  </a>
</template>

<script>
import { computed } from "vue";
import { IonImg } from "@ionic/vue";
export default {
  components: {
    IonImg,
  },
  setup() {
    const image = computed(() => require("../assets/images/dechema-logo.svg"));

    return {
      image,
    };
  },
};
</script>

<style lang="scss" scoped>
/*
ion-img {
  width: 50%;
  margin-left: auto;
  margin-right: auto;
}
*/
</style>
