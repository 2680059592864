<template>
  <ion-range
    min="0"
    max="100"
    @update:modelValue="usableElectricityPercentage = $event"
    :modelValue="usableElectricityPercentage"
  >
    <ion-label slot="start">{{ $t("electricitySources.usableElectricityPercentageRange.label") }}</ion-label>
    <ion-label slot="end">{{ this.usableElectricityPercentage }} %</ion-label>
  </ion-range>
</template>

<script>
import { mapFields } from "vuex-map-fields";
import { IonRange, IonLabel } from "@ionic/vue";
export default {
  components: {
    IonRange,
    IonLabel,
  },
  computed: {
    ...mapFields("settings", ["usableElectricityPercentage"]),
  },
};
</script>

<style lang="scss" scoped></style>
