<template>
  <calculation-value :prefix="prefix" :value="value" :suffix="suffix" id="miscellaneous-value"></calculation-value>
</template>

<script>
import CalculationValue from "./CalculationValue.vue";
export default {
  components: {
    CalculationValue,
  },
  props: {
    prefix: {
      type: String,
      required: false,
    },
    value: {
      type: String,
      required: true,
    },
    suffix: {
      type: String,
      required: false,
    },
  },
};
</script>

<style lang="scss" scoped>
#miscellaneous-value {
  top: 305px;
  left: 548px;
}

@media screen and (min-width: 576px) {
  #miscellaneous-value {
    top: 183px;
    left: 256px;
  }
}
@media screen and (min-width: 768px) {
  #miscellaneous-value {
    top: 250px;
    left: 348px;
  }
}
@media screen and (min-width: 992px) {
  #miscellaneous-value {
    top: 338px;
    left: 471px;
  }
}
@media screen and (min-width: 1200px) {
  #miscellaneous-value {
    top: 404px;
    left: 564px;
  }
}
</style>
