<template>
  <p
    v-html="
      $t('mainResults.resultInfo.explanations.intro', {
        country: this.country?.attributes?.label_de,
        year: this.getData?.year?.label,
        calculationType:
          this.getCalculationType() == 'offer'
            ? $t('mainResults.resultInfo.offer')
            : $t('mainResults.resultInfo.demand'),
        netProductT: formatNumber(this.getData.netProductT / 1000000, 'fixed', 2),
        product: this.product?.attributes[`label_${$i18n.locale}`],
      })
    "
  ></p>
  <template v-if="this.getCalculationType() == 'offer'">
    <p
      v-html="
        $t('mainResults.resultInfo.explanations.offer.electricityPotentials', {
          electricityPotential: formatNumber(this.getData.electricityPotentialTwh, 'fixed', 2, 'TWh'),
        })
      "
    ></p>
    <p
      v-if="this.getConsiderGrossElectricityConsumption() == true && this.getData.processElectricityTwh <= 0"
      v-html="
        $t('mainResults.resultInfo.explanations.offer.remainingElectricity.consideringGrossConsumption.none', {
          electricityOwnUseTwh: formatNumber(this.getData.electricityOwnUseTwh, 'fixed', 2, 'TWh'),
          processElectricityTwh: formatNumber(this.getData.processElectricityTwh, 'fixed', 2, 'TWh'),
          usableElectricityPercentage: formatNumber(this.getUsableElectricityPercentage(), 'fixed', 2, '%'),
          productLabel: this.product?.attributes[`label_${$i18n.locale}`],
        })
      "
    ></p>
    <p
      v-if="this.getConsiderGrossElectricityConsumption() == true && this.getData.processElectricityTwh > 0"
      v-html="
        $t('mainResults.resultInfo.explanations.offer.remainingElectricity.consideringGrossConsumption.any', {
          electricityOwnUseTwh: formatNumber(this.getData.electricityOwnUseTwh, 'fixed', 2, 'TWh'),
          processElectricityTwh: formatNumber(this.getData.processElectricityTwh, 'fixed', 2, 'TWh'),
          usableElectricityPercentage: formatNumber(this.getUsableElectricityPercentage(), 'fixed', 2, '%'),
          productLabel: this.product?.attributes[`label_${$i18n.locale}`],
        })
      "
    ></p>
    <p
      v-if="this.getConsiderGrossElectricityConsumption() == false && this.getData.processElectricityTwh <= 0"
      v-html="
        $t('mainResults.resultInfo.explanations.offer.remainingElectricity.notConsideringGrossConsumption.none', {
          electricityOwnUseTwh: formatNumber(this.getData.electricityOwnUseTwh, 'fixed', 2, 'TWh'),
          processElectricityTwh: formatNumber(this.getData.processElectricityTwh, 'fixed', 2, 'TWh'),
          usableElectricityPercentage: formatNumber(this.getUsableElectricityPercentage(), 'fixed', 2, '%'),
          productLabel: this.product?.attributes[`label_${$i18n.locale}`],
        })
      "
    ></p>
    <p
      v-if="this.getConsiderGrossElectricityConsumption() == false && this.getData.processElectricityTwh > 0"
      v-html="
        $t('mainResults.resultInfo.explanations.offer.remainingElectricity.notConsideringGrossConsumption.any', {
          electricityOwnUseTwh: formatNumber(this.getData.electricityOwnUseTwh, 'fixed', 2, 'TWh'),
          processElectricityTwh: formatNumber(this.getData.processElectricityTwh, 'fixed', 2, 'TWh'),
          usableElectricityPercentage: formatNumber(this.getUsableElectricityPercentage(), 'fixed', 2, '%'),
          productLabel: this.product?.attributes[`label_${$i18n.locale}`],
        })
      "
    ></p>

    <p
      v-if="this.getData.processElectricityTwh > 0"
      v-html="$t('mainResults.resultInfo.explanations.offer.electricityDistribution')"
    ></p>
  </template>
  <template v-else>
    <p v-html="$t('mainResults.resultInfo.explanations.demand')"></p>
  </template>
  <p v-html="$t('mainResults.resultInfo.explanations.tableIntro')"></p>
</template>

<script>
import { mapGetters } from "vuex";
import formatNumberMixin from "../../mixins/formatNumberMixin.js";
export default {
  computed: {
    ...mapGetters("calculations", ["getMeta", "getData"]),
    ...mapGetters("settings", [
      "getCalculationType",
      "getUsableElectricityPercentage",
      "getConsiderGrossElectricityConsumption",
    ]),
    country() {
      return this.$store.getters["countries/getById"](this.$store.state.settings.countryId);
    },
    product() {
      return this.$store.getters["products/getById"](this.$store.state.settings.productId);
    },
  },
  mixins: [formatNumberMixin],
};
</script>

<style lang="scss" scoped>
p {
  margin-bottom: 1rem;
}
</style>
