<template>
  <ion-footer collapse="fade">
    <div class="page-footer-content">
      <ion-grid fixed>
        <ion-row>
          <ion-col
            size="12"
            size-lg="2"
            class="display-flex ion-justify-content-center ion-align-items-center"
            style="padding-top: 1.5rem"
          >
            <dechema-logo></dechema-logo>
          </ion-col>
          <ion-col size="12" size-lg="5">
            <ion-row>
              <ion-col>
                <h4>{{ $t("pageFooter.management.title") }}</h4>
                <p>
                  <b>DECHEMA e.V. - Gesellschaft für Chemische Technik und Biotechnologie</b><br />
                  Theodor-Heuss-Allee 25<br />
                  60486 Frankfurt am Main
                </p>
                <p>DECHEMA | Gesellschaft für Chemische Technik und Biotechnologie e.V.</p>
                <p>
                  <b>{{ $t("pageFooter.management.contact") }}</b> Luisa López, Dr. Dinh Du Tran
                </p>
                <p>
                  <b>{{ $t("pageFooter.management.email") }}</b>
                  <a href="mailto:ptx-potentials@dechema.de">ptx-potentials@dechema.de</a>
                </p>
              </ion-col>
            </ion-row>
          </ion-col>
          <ion-col size="12" size-lg="5">
            <ion-row>
              <ion-col>
                <h4>{{ $t("pageFooter.project.title") }}</h4>
                <p v-html="$t('pageFooter.project.description')"></p>
              </ion-col>
            </ion-row>
            <ion-row>
              <ion-col class="display-flex ion-justify-content-center ion-align-items-center">
                <kopernikus-p2x-logo></kopernikus-p2x-logo>
              </ion-col>
              <ion-col class="display-flex ion-justify-content-center ion-align-items-center">
                <namosyn-logo></namosyn-logo>
              </ion-col>
              <ion-col class="display-flex ion-justify-content-center ion-align-items-center">
                <bmbf-logo></bmbf-logo>
              </ion-col>
            </ion-row>
          </ion-col>
        </ion-row>
      </ion-grid>
    </div>
  </ion-footer>
</template>

<script>
import { IonFooter, IonGrid, IonRow, IonCol } from "@ionic/vue";
import BmbfLogo from "../components/BmbfLogo.vue";
import KopernikusP2xLogo from "../components/KopernikusP2xLogo.vue";
import NamosynLogo from "../components/NamosynLogo.vue";
import DechemaLogo from "../components/DechemaLogo.vue";
export default {
  components: {
    IonFooter,
    IonGrid,
    IonRow,
    IonCol,
    BmbfLogo,
    KopernikusP2xLogo,
    NamosynLogo,
    DechemaLogo,
  },
};
</script>

<style lang="scss" scoped></style>
