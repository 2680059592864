<template>
  <ion-menu menu-id="app-menu" content-id="main-content">
    <ion-header>
      <ion-toolbar>
        <ion-title>Power-to-X</ion-title>
      </ion-toolbar>
    </ion-header>
    <ion-content>
      <ion-list>
        <template v-if="$i18n.locale == 'de'">
          <ion-item button @click="menuNavigation('/de')">
            <ion-label>Home</ion-label>
          </ion-item>
          <ion-item button @click="menuNavigation('/de/glossar')">
            <ion-label>Glossar/Begriffserläuterungen</ion-label>
          </ion-item>
          <ion-item button @click="menuNavigation('/de/technische-parameter')">
            <ion-label>Technische Parameter</ion-label>
          </ion-item>
          <ion-item button @click="menuNavigation('/de/ueber')">
            <ion-label>Über</ion-label>
          </ion-item>
        </template>
        <template v-if="$i18n.locale == 'en'">
          <ion-item button @click="menuNavigation('/en')">
            <ion-label>Home</ion-label>
          </ion-item>
          <ion-item button @click="menuNavigation('/en/glossary')">
            <ion-label>Glossary/Explanation of terms</ion-label>
          </ion-item>
          <ion-item button @click="menuNavigation('/en/technical-parameters')">
            <ion-label>Technical Parameters</ion-label>
          </ion-item>
          <ion-item button @click="menuNavigation('/en/about')">
            <ion-label>About</ion-label>
          </ion-item></template
        >
      </ion-list>
    </ion-content>
  </ion-menu>
</template>
<script>
import { IonMenu, IonHeader, IonContent, IonToolbar, IonTitle, IonItem, IonList, IonLabel } from "@ionic/vue";
import { menuController } from "@ionic/vue";
export default {
  components: {
    IonMenu,
    IonHeader,
    IonContent,
    IonToolbar,
    IonTitle,
    IonItem,
    IonList,
    IonLabel,
  },
  methods: {
    menuNavigation(url) {
      menuController.close("app-menu");
      this.$router.push(url);
    },
  },
};
</script>
