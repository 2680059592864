<template>
  <div class="calculation-label-container">
    <calculation-label :value="$t('mainCalculations.electrolysisWaterLabel.value')"></calculation-label>
  </div>
</template>

<script>
import CalculationLabel from "./CalculationLabel.vue";
export default { components: { CalculationLabel } };
</script>

<style lang="scss" scoped>
.calculation-label-container {
  position: absolute;
}

@media screen and (min-width: 576px) {
  .calculation-label-container {
    top: 33px;
    left: 251px;
  }
}
@media screen and (min-width: 768px) {
  .calculation-label-container {
    top: 51px;
    left: 341px;
  }
}
@media screen and (min-width: 992px) {
  .calculation-label-container {
    top: 73px;
    left: 461px;
  }
}
@media screen and (min-width: 1200px) {
  .calculation-label-container {
    top: 87px;
    left: 551px;
  }
}
</style>
