<template>
  <div class="calculation-label-container">
    <calculation-label
      v-if="this.product"
      :value="this.product.attributes[`label_${$i18n.locale}`]"
    ></calculation-label>
  </div>
</template>

<script>
import CalculationLabel from "./CalculationLabel.vue";
export default {
  components: { CalculationLabel },
  computed: {
    product() {
      return this.$store.getters["products/getById"](this.$store.state.settings.productId);
    },
  },
};
</script>

<style lang="scss" scoped>
.calculation-label-container {
  position: absolute;
}

@media screen and (min-width: 576px) {
  .calculation-label-container {
    top: 217px;
    left: 200px;
  }
}
@media screen and (min-width: 768px) {
  .calculation-label-container {
    top: 301px;
    left: 272px;
  }
}
@media screen and (min-width: 992px) {
  .calculation-label-container {
    top: 412px;
    left: 369px;
  }
}
@media screen and (min-width: 1200px) {
  .calculation-label-container {
    top: 493px;
    left: 440px;
  }
}
</style>
