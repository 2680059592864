export default {
  namespaced: true,
  state: {
    waterSources: [],
  },
  getters: {
    getAll: (state) => () => {
      return state.waterSources;
    },
    getById: (state) => (id) => {
      return state.waterSources.find((waterSource) => waterSource.id === id);
    },
    getDefaultWaterSource: (state) => () => {
      return state.waterSources.find((waterSource) => waterSource.attributes.identifier === "freshwater");
    },
  },
  actions: {
    async fetchAll({ commit }) {
      try {
        let data = [
          { id: 1, attributes: { identifier: "freshwater", label_de: "Süßwasser", label_en: "Fresh water" } },
          { id: 2, attributes: { identifier: "saltwater", label_de: "Salzwasser", label_en: "Salt water" } },
        ];
        commit("SET_WATER_SOURCES", data);
      } catch (error) {
        console.log(error);
      }
    },
  },
  mutations: {
    SET_WATER_SOURCES(state, waterSources) {
      state.waterSources = waterSources;
    },
  },
};
