<template>
  <ion-card
    :color="cardStyle == 'background' ? color : null"
    :class="cardStyle == 'quote' ? `ion-border-left ion-border-${color}` : null"
  >
    <ion-card-header v-if="title || displayIcon">
      <ion-card-title v-if="title">
        <ion-icon v-if="displayIcon" :icon="informationOutline" :class="`bg-${color}-shade`"></ion-icon>
        {{ title }}
      </ion-card-title>
    </ion-card-header>
    <ion-card-content>
      <slot />
    </ion-card-content>
  </ion-card>
</template>

<script>
import { IonCard, IonCardHeader, IonCardTitle, IonCardContent, IonIcon } from "@ionic/vue";
import { informationOutline } from "ionicons/icons";
export default {
  components: { IonCard, IonCardHeader, IonCardTitle, IonCardContent, IonIcon },
  props: {
    title: {
      type: String,
      required: false,
    },
    color: {
      type: String,
      required: true,
      default: "primary",
    },
    cardStyle: {
      type: String,
      required: false,
      default: "background",
    },
    displayIcon: {
      type: Boolean,
      default: false,
    },
  },
  setup() {
    return { informationOutline };
  },
};
</script>

<style lang="scss" scoped>
ion-card[class^="ion-border-"] {
  border-width: 3px;
}
</style>
