<template>
  <div class="calculation-label-container">
    <calculation-label :value="$t('mainCalculations.co2Label.value')"></calculation-label>
  </div>
</template>

<script>
import CalculationLabel from "./CalculationLabel.vue";
export default { components: { CalculationLabel } };
</script>

<style lang="scss" scoped>
.calculation-label-container {
  position: absolute;
}

@media screen and (min-width: 576px) {
  .calculation-label-container {
    top: 428px;
    left: 294px;
  }
}
@media screen and (min-width: 768px) {
  .calculation-label-container {
    top: 588px;
    left: 400px;
  }
}
@media screen and (min-width: 992px) {
  .calculation-label-container {
    top: 800px;
    left: 541px;
  }
}
@media screen and (min-width: 1200px) {
  .calculation-label-container {
    top: 956px;
    left: 647px;
  }
}
</style>
