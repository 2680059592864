<template>
  <div class="calculation-label-container">
    <calculation-label :value="$t('mainCalculations.electrolysisElectricityLabel.value')"></calculation-label>
  </div>
</template>

<script>
import CalculationLabel from "./CalculationLabel.vue";
export default { components: { CalculationLabel } };
</script>

<style lang="scss" scoped>
.calculation-label-container {
  position: absolute;
}

@media screen and (min-width: 576px) {
  .calculation-label-container {
    top: 197px;
    left: 201px;
  }
}
@media screen and (min-width: 768px) {
  .calculation-label-container {
    top: 274px;
    left: 272px;
  }
}
@media screen and (min-width: 992px) {
  .calculation-label-container {
    top: 374px;
    left: 368px;
  }
}
@media screen and (min-width: 1200px) {
  .calculation-label-container {
    top: 448px;
    left: 440px;
  }
}
</style>
