<template>
  <alert-card color="warning">
    <slot />
  </alert-card>
</template>

<script>
import { mapGetters } from "vuex";
import AlertCard from "./AlertCard.vue";
export default {
  components: {
    AlertCard,
  },
  props: {
    filter: {
      type: Array,
      default: () => [],
    },
  },
  computed: {
    ...mapGetters("calculations", ["getWarnings"]),
    warningKeys: function () {
      return Object.keys(this.getWarnings());
    },
    warnings: function () {
      if (this.filter.length > 0) {
        return this.warningKeys
          .filter((key) => this.filter.includes(key))
          .reduce((cur, key) => {
            return Object.assign(cur, { [key]: this.getWarnings()[key] });
          }, {});
      } else {
        return this.getWarnings();
      }
    },
  },
};
</script>

<style lang="scss" scoped></style>
