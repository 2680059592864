<template>
  <apex-chart type="bar" :options="options" :series="series" width="100%" heigth="100%"></apex-chart>
</template>

<script>
import { mapGetters } from "vuex";
import formatNumberMixin from "../../mixins/formatNumberMixin.js";
import { getLocale } from "../../i18n";

export default {
  mixins: [formatNumberMixin],
  computed: {
    ...mapGetters("calculations", ["getElectricityPotentials"]),
    series() {
      return [
        {
          name: "electricityPotentails",
          data: this.values,
        },
      ];
    },
    categories() {
      return this.getElectricityPotentials.map((ep) => ep[`label${this.capitalize(getLocale())}`]);
    },
    values() {
      return this.getElectricityPotentials.map((ep) => ep.valueTwh);
    },
    options() {
      return {
        chart: {
          id: "electricity-input-chart",
          toolbar: { show: false },
        },
        plotOptions: {
          bar: {
            horizontal: true,
            dataLabels: {
              position: "bottom",
            },
          },
        },
        colors: ["#7fc818", "#7fc818", "#86D419", "#6EAD15", "#9ED057", "#5E9412", "#568710", "#2D47098"],
        dataLabels: {
          enabled: true,
          textAnchor: "start",
          style: {
            colors: ["rgb(115, 115, 115)"],
          },
          formatter: (val, opt) => {
            if (val === null) {
              return opt.w.globals.labels[opt.dataPointIndex] + ": n/a";
            } else {
              return opt.w.globals.labels[opt.dataPointIndex] + ":  " + this.formatNumber(val, "fixed", 2, "TWh");
            }
          },
          offsetX: 0,
        },
        xaxis: {
          categories: this.categories,
        },
        yaxis: {
          labels: {
            show: false,
          },
        },
      };
    },
  },
  methods: {
    capitalize: (s) => {
      if (typeof s !== "string") return "";
      return s.charAt(0).toUpperCase() + s.slice(1);
    },
  },
};
</script>
