import axios from "axios";
import debounce from 'lodash.debounce';
import { getLocale } from '../../i18n';

export default {
  namespaced: true,
  state: {
    input: {},
    output: {
      data: { electricityPotentials: [] }
    },
  },
  getters: {
    getAvailableCo2T(state) {
      return parseFloat(state.output.data.availableCo2T);
    },
    getNeededCo2T(state) {
      return parseFloat(state.output.data.neededCo2T);
    },
    getElectricityPotentialTwh(state) {
      return parseFloat(state.output.data.electricityPotentialTwh);
    },
    getElectricityPotentials(state) {
      if (state.output.data.electricityPotentials === undefined) {
        return []
      } else {
        return state.output.data.electricityPotentials.map(ep => ({ ...ep, valueTwh: parseFloat(ep.valueTwh) }))
      }
    },
    getElectricityMiscellaneousTwh(state) {
      return parseFloat(state.output.data.electricityMiscellaneousTwh);
    },
    getElectricityOwnUseTwh(state) {
      return parseFloat(state.output.data.electricityOwnUseTwh);
    },
    getProcessElectricityTwh(state) {
      return parseFloat(state.output.data.processElectricityTwh);
    },
    getWaterElectricityTwh(state) {
      return parseFloat(state.output.data.waterElectricityTwh);
    },
    getCo2ElectricityTwh(state) {
      return parseFloat(state.output.data.co2ElectricityTwh);
    },
    getElectrolysisElectricityTwh(state) {
      return parseFloat(state.output.data.electrolysisElectricityTwh);
    },
    getProductElectricityTwh(state) {
      return parseFloat(state.output.data.productElectricityTwh);
    },
    getProductHydrogenT(state) {
      return parseFloat(state.output.data.productHydrogenT);
    },
    getNetProductT(state) {
      return parseFloat(state.output.data.netProductT);
    },
    getGrossProductT(state) {
      return parseFloat(state.output.data.grossProductT);
    },
    getProductHeatTwh(state) {
      return parseFloat(state.output.data.productHeatTwh);
    },
    getProductCo2T(state) {
      return parseFloat(state.output.data.productCo2T);
    },
    getCo2HeatTwh(state) {
      return parseFloat(state.output.data.co2HeatTwh);
    },
    getWaterHeatTwh(state) {
      return parseFloat(state.output.data.waterHeatTwh);
    },
    getAvailableWaterT(state) {
      return parseFloat(state.output.data.availableWaterT);
    },
    getNeededWaterT(state) {
      return parseFloat(state.output.data.neededWaterT);
    },
    getProductWaterT(state) {
      return parseFloat(state.output.data.productWaterT);
    },
    getWaterFractionOfFreshWaterPercent(state) {
      return parseFloat(state.output.data.waterFractionOfFreshWaterPercent);
    },
    getHeatTwh(state) {
      return parseFloat(state.output.data.heatTwh);
    },
    getYear(state) {
      return state.output.data.year;
    },
    getWarnings: (state) => () => {
      return state.output.warnings || [];
    },
    getMeta(state) {
      return state.output.meta || {};
    },
    getData(state) {
      return state.output.data || {};
    },
    getInput(state) {
      return state.input || {};
    },
  },
  mutations: {
    UPDATE_INPUT(state, payload) {
      state.input = payload;
      this.dispatch("calculations/debounceFetchCalculations");
    },
    UPDATE_OUTPUT(state, payload) {
      state.output = payload;
    },
    SET_OUTPUT(state, output) {
      state.output = output;
    },
  },
  actions: {
    debounceFetchCalculations: debounce(({ dispatch }) => {
      dispatch("fetchCalculations");
    }, 500),
    async fetchCalculations({ commit }) {
      commit("errors/SET_ERROR", null, { root: true });
      try {
        const params = { data: this.state.calculations.input }
        const data = await axios.post(`${process.env.VUE_APP_BACKEND_BASE_URL}/calculate/${this.state.calculations.input.yearId}?locale=${getLocale()}`, params, {
          headers: {
            "Content-Type": "application/json",
            "Accept": "application/json",
            Authorization: `Bearer ${process.env.VUE_APP_BACKEND_API_TOKEN}`,
          },
        });
        commit("SET_OUTPUT", data.data);
      } catch (error) {
        commit("errors/SET_ERROR", error, { root: true });
        commit("errors/SET_OPEN", true, { root: true });
      }
    },
  },
};
