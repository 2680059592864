<template>
  <ion-card id="co2-sources-settings">
    <ion-accordion-group value="open">
      <ion-accordion value="open">
        <ion-item slot="header">
          <ion-card-header>
            <ion-card-title>
              <ion-icon :icon="cloudOutline"></ion-icon>
              <ion-label v-html="$t('co2Sources.title')"></ion-label>
            </ion-card-title>
          </ion-card-header>
        </ion-item>

        <ion-card-content slot="content">
          <calculation-warnings
            :filter="[
              'co2Requirement',
              'co2SourceCategory',
              'co2Sources',
              'electricityPerCo2Co2SourceCategory',
              'heatCo2SourceCategory',
              'heatCo2Sources',
            ]"
          ></calculation-warnings>
          <template v-if="this.getWarnings().electricityPerCo2Co2Sources != null">
            <limit-warning>{{ $t("co2Sources.choicePrompt") }}</limit-warning>
          </template>
          <co2-source-category-select></co2-source-category-select>
          <co2-source-select></co2-source-select>
        </ion-card-content>
      </ion-accordion>
    </ion-accordion-group>
  </ion-card>
</template>

<script>
import { mapGetters } from "vuex";
import CalculationWarnings from "./CalculationWarnings.vue";
import LimitWarning from "./LimitWarning.vue";
import Co2SourceCategorySelect from "./Co2Sources/Co2SourceCategorySelect.vue";
import Co2SourceSelect from "./Co2Sources/Co2SourceSelect.vue";
import {
  IonAccordion,
  IonAccordionGroup,
  IonCard,
  IonCardTitle,
  IonCardHeader,
  IonCardContent,
  IonLabel,
  IonItem,
  IonIcon,
} from "@ionic/vue";
import { cloudOutline } from "ionicons/icons";
export default {
  components: {
    IonAccordion,
    IonAccordionGroup,
    IonCard,
    IonCardTitle,
    IonCardHeader,
    IonCardContent,
    IonLabel,
    IonItem,
    IonIcon,
    CalculationWarnings,
    Co2SourceCategorySelect,
    Co2SourceSelect,
    LimitWarning,
  },
  computed: {
    ...mapGetters("calculations", ["getWarnings"]),
  },
  setup() {
    return { cloudOutline };
  },
};
</script>

<style lang="scss" scoped></style>
