import axios from "axios";

export default {
  namespaced: true,
  state: {
    countries: [],
  },
  getters: {
    getAll: (state) => () => {
      return state.countries;
    },
    getById: (state) => (id) => {
      return state.countries.find((country) => country.id === id);
    },
    getByIdentifier: (state) => (identifier) => {
      return state.countries.find((country) => country.attributes.identifier === identifier);
    },
    getDefaultCountry: (state) => () => {
      return state.countries.find((country) => country.attributes.identifier === "DEU");
    },
  },
  actions: {
    async fetchAll({ commit }) {
      try {
        const data = await axios.get(`${process.env.VUE_APP_BACKEND_BASE_URL}/countries?sort_by=label_de`, {
          headers: {
            "Content-Type": "application/json",
            "Accept": "application/json",
            Authorization: `Bearer ${process.env.VUE_APP_BACKEND_API_TOKEN}`,
          },
        });
        commit("SET_COUNTRIES", data.data.data);
      } catch (error) {
        console.log(error);
      }
    },
  },
  mutations: {
    SET_COUNTRIES(state, countries) {
      state.countries = countries;
    },
  },
};
