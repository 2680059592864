<template>
  <bar-chart :chart-data="chartData" :options="chartOptions" />
</template>

<script>
import { defineComponent } from "vue";
import { mapGetters } from "vuex";
import { BarChart } from "vue-chart-3";
import { Chart, registerables } from "chart.js";
import formatNumberMixin from "../../mixins/formatNumberMixin.js";

Chart.register(...registerables);

export default defineComponent({
  mixins: [formatNumberMixin],
  components: { BarChart },
  computed: {
    ...mapGetters("calculationsMany", ["getGrossProductT"]),
    ...mapGetters("calculationsMany", ["getData", "getYears"]),
    chartOptions() {
      return {
        responsive: true,
        plugins: {
          legend: { display: false },
          tooltip: {
            callbacks: {
              label: (context) => {
                return `${context.label}: ${this.formatNumber(context.parsed.y, "fixed", 2, "Mio. t")}`;
              },
            },
          },
        },
      };
    },
    chartData() {
      return {
        labels: this.getYears().map((year) => year?.label),
        datasets: [
          {
            label: "Produktpotenzial in Mio. t",
            data: this.getYears().map((year) => this.getGrossProductT(year?.label) / 1000000),
            backgroundColor: ["#77CEFF", "#0079AF", "#123E6B", "#97B0C4", "#A5C8ED"],
          },
        ],
      };
    },
  },
});
</script>
