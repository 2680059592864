<template>
  <ion-footer>
    <ion-grid fixed>
      <ion-row>
        <ion-col>
          <span>Copyright 2022 DECHEMA, Gesellschaft für Chemische Technik und Biotechnologie e.V.</span>
          <span class="pull-right">
            <a href="https://dechema.de/impressum.html" target="_blank">{{ $t("legalFooter.imprint") }}</a> |
            <a href="https://dechema.de/datenschutz.html" target="_blank">{{ $t("legalFooter.privacyPolicy") }}</a>
          </span>
        </ion-col>
      </ion-row>
    </ion-grid>
  </ion-footer>
</template>

<script>
import { computed } from "vue";
import { IonFooter, IonCol, IonRow, IonGrid } from "@ionic/vue";
export default {
  components: {
    IonFooter,
    IonCol,
    IonRow,
    IonGrid,
  },
  setup() {
    const image = computed(() => require("../assets/images/beegoodit-logo.png"));

    return {
      image,
    };
  },
};
</script>

<style lang="scss" scoped>
ion-footer {
  min-height: 3rem;
  line-height: 1.75rem;
  background-color: var(--ion-color-primary);
  color: var(--ion-color-primary-contrast);

  a {
    color: var(--ion-color-primary-contrast);
  }
}

.pull-right {
  float: right;
}
</style>
