import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";

import { IonicVue } from "@ionic/vue";

/* Layouts */
import DefaultLayout from "./layouts/DefaultLayout.vue";
import StickyFooterLayout from "./layouts/StickyFooterLayout.vue";

/* Fonts */
import "@fontsource/pt-sans";

/* Flag icons */

import "flag-icons/sass/flag-icons.scss";

/* Core CSS required for Ionic components to work properly */
import "@ionic/vue/css/core.css";

/* Basic CSS for apps built with Ionic */
import "@ionic/vue/css/normalize.css";
import "@ionic/vue/css/structure.css";
import "@ionic/vue/css/typography.css";

/* Optional CSS utils that can be commented out */
import "@ionic/vue/css/padding.css";
import "@ionic/vue/css/float-elements.css";
import "@ionic/vue/css/text-alignment.css";
import "@ionic/vue/css/text-transformation.css";
import "@ionic/vue/css/flex-utils.css";
import "@ionic/vue/css/display.css";

/* Theme variables */
import "./theme/variables.css";

/* Core variables */
import "./theme/core.scss";
import "./theme/composition.scss";
import "./theme/utility.scss";
import "./theme/block.scss";
import "./theme/exception.scss";

import "./registerServiceWorker";

/* Vuex stores */
import store from "./store";

/* Apex charts */
import VueApexCharts from "vue3-apexcharts";

/* i18n */
import { getLocale, i18n } from "./i18n"

const app = createApp(App).use(i18n).use(IonicVue).use(router).use(store).use(VueApexCharts);

i18n.global.locale = getLocale()

app.component("default-layout", DefaultLayout);
app.component("sticky-footer-layout", StickyFooterLayout);
app.component("apex-chart", VueApexCharts);

router.isReady().then(() => {
  app.mount("#app");
});
