<template>
  <ion-footer>
    <ion-grid>
      <ion-row>
        <ion-col class="text-right">
          <span
            >Powered by
            <a href="https://www.beegoodit.de/" target="_blank"
              ><ion-img class="bgit-logo" :src="image" />BeeGood IT</a
            ></span
          >
        </ion-col>
      </ion-row>
    </ion-grid>
  </ion-footer>
</template>

<script>
import { computed } from "vue";
import { IonFooter, IonCol, IonRow, IonGrid, IonImg } from "@ionic/vue";
export default {
  components: {
    IonFooter,
    IonCol,
    IonRow,
    IonGrid,
    IonImg,
  },
  setup() {
    const image = computed(() => require("../assets/images/beegoodit-logo.png"));

    return {
      image,
    };
  },
};
</script>

<style lang="scss" scoped>
ion-footer {
  height: 2rem;
  background-color: var(--ion-color-dark);
  color: var(--ion-color-primary-contrast);

  ion-grid {
    padding: 0;
  }

  a {
    color: var(--ion-color-primary-contrast);
  }

  span {
    padding-right: 0.5rem;
  }

  .bgit-logo {
    height: 1rem;
    width: 1rem;
    display: inline-block;
    margin-left: 0.25rem;
    margin-right: 0.25rem;
    vertical-align: middle;
  }
}
</style>
