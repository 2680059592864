<template>
  <ion-page>
    <ion-header>
      <ion-toolbar color="primary">
        <ion-buttons slot="start">
          <ion-button @click="openMenu()" expand="full">
            <ion-icon slot="icon-only" :icon="menuOutline"></ion-icon>
          </ion-button>
        </ion-buttons>
        <ion-title>{{ pageTitle }}</ion-title>
        <ion-buttons slot="end">
          <ion-back-button :default-href="pageDefaultBackLink"></ion-back-button>
        </ion-buttons>
      </ion-toolbar>
    </ion-header>
    <ion-content id="main-content">
      <slot />
    </ion-content>
    <page-footer></page-footer>
    <bgit-footer></bgit-footer>
    <main-toast></main-toast>
  </ion-page>
</template>

<script>
import {
  IonBackButton,
  IonButtons,
  IonButton,
  IonPage,
  IonHeader,
  IonTitle,
  IonToolbar,
  IonContent,
  IonIcon,
} from "@ionic/vue";
import { menuOutline } from "ionicons/icons";
import { menuController } from "@ionic/vue";
import PageFooter from "../components/PageFooter.vue";
import MainToast from "../components/MainToast.vue";

export default {
  props: {
    pageTitle: {
      type: String,
      default: "Power-to-X",
    },
    pageDefaultBackLink: {},
  },
  components: {
    IonBackButton,
    IonButtons,
    IonButton,
    IonPage,
    IonHeader,
    IonTitle,
    IonToolbar,
    IonContent,
    IonIcon,
    PageFooter,
    MainToast,
  },
  methods: {
    openMenu() {
      menuController.open("app-menu");
    },
  },
  setup() {
    return { menuOutline };
  },
};
</script>

<style lang="scss" scoped>
page-footer * {
  background-color: red;
}
</style>
