<template>
  <default-layout>
    <main-hero></main-hero>
    <ion-grid fixed>
      <ion-row>
        <ion-col>
          <main-intro></main-intro>
        </ion-col>
      </ion-row>
      <ion-row>
        <ion-col>
          <general-settings></general-settings>
        </ion-col>
      </ion-row>
      <ion-row v-if="this.$route.query.debug == 'true'">
        <ion-col>
          <debugging-info></debugging-info>
        </ion-col>
      </ion-row>
      <ion-row>
        <ion-col size="12" size-lg="4">
          <electricity-sources></electricity-sources>
        </ion-col>
        <ion-col size="12" size-lg="4">
          <water-sources></water-sources>
        </ion-col>
        <ion-col size="12" size-lg="4">
          <co2-sources></co2-sources>
        </ion-col>
      </ion-row>
      <ion-row>
        <ion-col>
          <main-calculations></main-calculations>
        </ion-col>
      </ion-row>
      <ion-row>
        <ion-col>
          <main-visualization></main-visualization>
        </ion-col>
      </ion-row>
      <ion-row>
        <ion-col>
          <main-results></main-results>
        </ion-col>
      </ion-row>
    </ion-grid>
  </default-layout>
</template>

<script>
import { IonGrid, IonRow, IonCol } from "@ionic/vue";
import GeneralSettings from "../components/GeneralSettings.vue";
import WaterSources from "../components/WaterSources.vue";
import ElectricitySources from "../components/ElectricitySources.vue";
import Co2Sources from "../components/Co2Sources.vue";
import MainHero from "../components/MainHero.vue";
import MainIntro from "../components/MainIntro.vue";
import MainCalculations from "../components/MainCalculations.vue";
import MainResults from "../components/MainResults.vue";
import MainVisualization from "../components/MainVisualization.vue";
import DebuggingInfo from "../components/DebuggingInfo.vue";

export default {
  components: {
    IonGrid,
    IonRow,
    IonCol,
    GeneralSettings,
    MainHero,
    MainIntro,
    MainResults,
    WaterSources,
    ElectricitySources,
    Co2Sources,
    MainCalculations,
    MainVisualization,
    DebuggingInfo,
  },
};
</script>

<style lang="scss" scoped></style>
