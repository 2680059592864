import { createRouter, createWebHistory } from "@ionic/vue-router";

import HomePage from "../pages/HomePage.vue";

const routes = [
  {
    path: "/",
    redirect: "/de",
  },
  {
    path: "/de",
    component: HomePage,
  },
  {
    path: "/en",
    component: HomePage,
  },
  {
    path: "/de/glossar",
    component: () => import("../pages/GlossaryPage.de.vue"),
  },
  {
    path: "/de/technische-parameter",
    component: () => import("../pages/TechnicalParametersPage.vue"),
  },
  {
    path: "/de/ueber",
    component: () => import("../pages/AboutPage.de.vue"),
  },
  {
    path: "/en/glossary",
    component: () => import("../pages/GlossaryPage.en.vue"),
  },
  {
    path: "/en/technical-parameters",
    component: () => import("../pages/TechnicalParametersPage.vue"),
  },
  {
    path: "/en/about",
    component: () => import("../pages/AboutPage.en.vue"),
  },
  {
    path: "/:catchAll(.*)",
    component: () => import("../pages/Http404Page.vue"),
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

export default router;
